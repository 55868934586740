// ------------------------ SIGNICAT ----------------------------------------------------------------------------------

import { sharedenvironment } from './shared';
import { monitorUrls } from './monitorService';
import { frontendNamesProd, serverConfigsProd } from './systemNamesAndUrls';

const APP_BASE_URL = 'https://apps.orgbrain.ai/';

const myenvironment = {
  production: true,
  name: 'PROD',
  frontendList: frontendNamesProd,
  debug: false,
  defaultServerConfig: serverConfigsProd,
  academyBucketName: 'academy-base',
  monitorFrontendUrl: 'https://monitor.orgbrain.ai?target=production',
  logServiceUrl: monitorUrls.production.url, // DEPreCAte ME
  appBase: APP_BASE_URL,
  // mainServerUrl: 'https://prod-server.orgbrain.ai',

  meetingEmailRecipientDomain: 'meeting.orgbrain.ai',
  logoURL: 'https://orgbrain-prod-site.s3.eu-central-1.amazonaws.com/',

  vapidPubKey:
    'BFgOaLG4mMaNglU4un2hG43LpekcCp0dBDzi67T86zSEZQFzTTfT7v2M8tp1LUgsl133JvXfD6-0a47lEPfpLRE',
  bankidSIGNICAT: {
    client_id: 'prod.kycsec.orgbrain.no.9755c',
    // redirect: `${APP_BASE_URL}auth/index.html`,
    redirectPath: `/auth/index.html`, // Add to origin to get full redirect url
    auth: 'nbid',
    authURL: 'https://id.signicat.com/oidc/authorize',
  },

  azets: {
    client_id: 'orgbrain_sso',
    redirect: `${APP_BASE_URL}`,
    authURL: 'https://login.azets.com/oauth/login',
    jwksURL: 'https://login.azets.com/api/v1/oauth2/jwks',
  },

  bankid: {
    client_id: 'urn:my:application:identifier:890424',
    redirectPath: `/auth/index.html`,
    auth: 'nbid',
    authURL: 'https://orgbrain.criipto.id/oauth2/authorize',
  },

  bankid2FA: false,
  SERVER_VERSION_CHECK: true,
  loguncaughtErrors: true,
  adobe_embedded_key: '0c6e8543607b458d8543b46c2aa2c341',
  showbankIdLogon: false,
};

export const environment = Object.assign(sharedenvironment, myenvironment);
