// // These guys can be used as keys  in the database.
export class Notifications {
  static notificationIndices = {
    organizationId: true,
    meetingId: true,
    direntId: true,
    signOrderId: true,
    requestId: true,
    taskId: true,
    userId: true,
    email: true,
    code: true,
    signicatOrderId: true,
  };

  // Thes are not keys so can be stored in a data object.
  // This filters out all the crap that seems to have accumulated.
  // We need all the fileds that must be persisted to recreate a notifiction in here.
  static allowedFields = {};
}

export enum DistributionMediums {
  EMAIL = 'EMAIL',
  EMAIL_WITH_ATTACHMENT = 'EMAIL_WITH_ATTACHMENT',
  SMS = 'SMS',
  BELL = 'BELL',
  PUSH = 'PUSH',
}

export const requiredFields = {};
requiredFields[DistributionMediums.EMAIL] = {
  lang: true,
  emailType: true,
  organizationId: true,
  recipient: { email: true },
  sender: { email: true },
};

requiredFields[DistributionMediums.EMAIL_WITH_ATTACHMENT] = {
  lang: true,
  emailType: true,
  organizationId: true,
  recipient: { email: true },
  sender: { email: true },
  emailWithAttachmentPayload: true,
};

requiredFields[DistributionMediums.BELL] = {
  recipient: { userId: true },
};

requiredFields[DistributionMediums.SMS] = {
  phone: true,
  message: true,
};

requiredFields[DistributionMediums.PUSH] = {
  recipient: { userId: true },
  url: true,
  webPushPayload: {
    notificationId: true,
    message: true,
    title: true,
  },
};

// Various consumers require different forms of this so all optional
export class NotificationUser {
  _id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  userId?: string;

  static create(user: {
    email?: string;
    firstName?: string;
    lastName?: string;
    _id?: any;
    userId?: string;
  }): NotificationUser {
    return {
      _id: user.userId || (user._id && user._id.toString()),
      userId: user.userId || (user._id && user._id.toString()),
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    };
  }
}

export class EmailInfo {
  recipient?: NotificationUser;
  entityName?: string;
  url?: string;
  sender?: NotificationUser;
  lang?: string;
  emailType?: EmailTypeEnum;
  orgId?: any;
  client?: any;
  emailCode?: string;
  meetingTitle?: string;
  meetingType?: string;
  userInput?: string;
  time?: string;
  endTime?: string;
  location?: string;
  subject?: string;
  textArray?: string[];
  orgName?: string;
  paramsForGenericEmailTemplate?: ParamsForCustomEmailTemplate;
  reminderSubject?: any;
  procedureType?: string;
  preVotingEnabled?: boolean;
  proxyInstructions?: string;
}

export type EmailWithAttachmentPayload = {
  requesterOrganizationId: string;
  displayedOrganizationId: string;
  meetingId: string;
  timestamp: number;
  emailSubject: string;
  finalOrgName: string;
  icsAlarmDescription: string;
  procedureType: string;
  preVotingEnabled?: boolean;
  proxyInstructions?: string;
  icsMainDescription: string;
  locale: string;
  userInputText: string;
  emailAddressFrom: string;
  recipientUserIds: string[];
  meetingUrl: string;
  meetingType: string;
  arbitratyDate?: string;
  arbitratyDuration?: number;
  arbitratyAdditionalUserInput?: any[];
  direntId?: string;
};

// Recipient data often a list
// This is merged into the shared payload to form the full payload
export interface RecipientPayload extends EmailInfo {
  recipient?: NotificationUser; //  email
  due?: Date;
  url?: string;
  paramsForGenericEmailTemplate?: ParamsForCustomEmailTemplate;
  paramsForBellTemplate?: ParamsForBellTemplate;
  webPushPayload?: PushEventPayload;
  taskId?: string;
}

// Essential fields for shared notifactions in here
export interface SharedNotificationPayloadBase {
  organizationId?: string;
  sender?: NotificationUser;
  code?: string;
}

export interface SharedSigningPayload {
  // orderId?: string;
  recipient?: NotificationUser;
  signicatOrderId?: string;
}
export interface SharedNotificationPayload
  extends SharedNotificationPayloadBase,
    BellEventPayload,
    SMSEventPayload,
    EmailEventPayload,
    SharedSigningPayload {}

export interface CombinedNotificationPayload extends SharedNotificationPayload, RecipientPayload {}

export type HelperFunc = (recipient, data) => any;

export interface EmailTags {
  Name: string;
  Value: string;
}

export interface SMSEventPayload extends SharedNotificationPayloadBase {
  subject?: string;
  message?: string;
  phone?: string;
}

export interface EmailEventPayload extends EmailInfo, SharedNotificationPayloadBase {
  organizationId?: string;
  configurationName?: string;
  tags?: EmailTags[];
  emailWithAttachmentPayload?: EmailWithAttachmentPayload;
}

export class ParamsForBellTemplate {
  title: string;
  documentName?: string;
  organizationName?: string;
  inviterName?: string;
  inviteeName?: string;
  surveyName?: string;
  signerName?: string;
  url?: string;
  address?: string;
  topic?: string;
  notes?: string;
}

export interface BellEventPayload extends SharedNotificationPayloadBase {
  due?: Date;
  formattedTime?: string;
  recipient?: NotificationUser;

  paramsForBellTemplate?: ParamsForBellTemplate;
  email?: string;
  organizationId?: string;
  meetingId?: string;
  direntId?: string;
  signOrderId?: string;
  requestId?: string;
  taskId?: string;
}

export interface PushEventPayload {
  notificationId: string;
  message: string;
  title: string;
}

export type AnyNotificationPayload = BellEventPayload | EmailEventPayload | SMSEventPayload;

export type ParamsForCustomEmailTemplate = {
  mainHeadline?: string;
  optionalUserInput?: string;
  firstLineInBold?: string;
  bodyTexts: string[];
  callToAction?: string;
  actionButtonText?: string;
  actionButtonUrl?: string;
  subject?: string;
};

export enum EmailTypeEnum {
  // Cerberus templates
  GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS = 'GENERIC_EMAIL_WITH_NO_PREDEFINED_TEXTS',
  MEETING_INVITE = 'MEETING_INVITE',
  MEETING_VIDEO_INVITE = 'MEETING_VIDEO_INVITE',
  MEETING_INVITE_TO_DISCUSSION = 'MEETING_INVITE_TO_DISCUSSION',
  MEETING_INVITE_TO_SIGN = 'MEETING_INVITE_TO_SIGN',
  MEETING_INVITE_TO_SIGNV2 = 'MEETING_INVITE_TO_SIGNV2',
  SHAREHOLDER_REGISTER = 'SHAREHOLDER_REGISTER',
  NORMAL_REGISTER_WITH_INVITER = 'NORMAL_REGISTER_WITH_INVITER',
  NORMAL_REGISTER_NO_INVITER = 'NORMAL_REGISTER_NO_INVITER',
  ORGANIZATION_INVITE = 'ORGANIZATION_INVITE',
  ORGANIZATION_INVITE_NO_INVITER = 'ORGANIZATION_INVITE_NO_INVITER',
  RESET_REGISTER = 'RESET_REGISTER',
  TRIAL_REGISTER = 'TRIAL_REGISTER',
  TRIAL_EXISTING = 'TRIAL_EXISTING',
  TRANSFER_INVITE = 'TRANSFER_INVITE',
  TRANSFER_EXISTING_INVITE = 'TRANSFER_EXISTING_INVITE',
}

// Index fileds dor reminder records.
