export enum ModuleEnum {
  meetings = 'meetings',
  shareholders = 'shareholders',
  news = 'news',
  files = 'files',
  tasks = 'tasks',
  chat = 'chat',
  boardschool = 'boardschool',
  boardmatch = 'boardmatch',
  surveys = 'surveys',
  academy = 'academy',
  organization = 'organization',
  investments = 'investments',
  templates = 'templates',
  configure = 'configure',
  admin = 'admin',

}
