import { CompanyTerminologyEnum } from './companyTypesWithTypeChecking';

export type BooleanMap = { [key: string]: boolean };

export type RoleBooleanMap = { [key in Role]: boolean };
export type PermissionBooleanMap = { [key in PermissionEnum]: boolean };
export type OptionalPermissionBooleanMap = { [key in PermissionEnum]?: boolean };
export type OptionalRoleBooleanMap = { [key in Role]?: boolean };

export enum Role {
  ADMIN = 'ADMIN',
  USER_ADMIN = 'USER_ADMIN',
  CHAIR = 'CHAIR',
  BOARDMEMBER = 'BOARDMEMBER',
  CEO = 'CEO',
  BOARDSECRETARY = 'BOARDSECRETARY',
  DEPUTYCHAIR = 'DEPUTYCHAIR',
  DEPUTYMEMBER = 'DEPUTYMEMBER',
  OBSERVER = 'OBSERVER',
  SHAREHOLDER = 'SHAREHOLDER',
  AUDITOR = 'AUDITOR',
  CONTACTPERSON = 'CONTACTPERSON',
  SHAREHOLDER_PROXY = 'SHAREHOLDER_PROXY',
  BUYER = 'BUYER',
  SCHOOL = 'SCHOOL',
  OWNER = 'OWNER',
  SELLER = 'SELLER',
  SELLER_ADMIN = 'SELLER_ADMIN',
  BUYER_ADMIN = 'BUYER_ADMIN',
  ORGBRAIN_SUPPORT = 'ORGBRAIN_SUPPORT',
  BOARDMATCH_ADMIN = 'BOARDMATCH_ADMIN',
  ROLE_1 = 'ROLE_1',
  ROLE_2 = 'ROLE_2',
  ROLE_3 = 'ROLE_3',
  ROLE_4 = 'ROLE_4',
  ROLE_5 = 'ROLE_5',
  ROLE_6 = 'ROLE_6',
}

export const nonEditableRoles: OptionalRoleBooleanMap = {
  OWNER: true,
  SHAREHOLDER: true,
};

// This maynot be used ?
export const roleSummaryDescriptionList = {
  ADMIN: { en: 'Organization Administrator Summary' },
  USER_ADMIN: { en: 'User Management Summary' },
  CHAIR: { en: 'Chair Summary' },
  BOARDMEMBER: { en: 'Board member Summary' },
  CEO: { en: 'CEO Summary' },
  BOARDSECRETARY: { en: 'Board Secretary Summary' },
  DEPUTYCHAIR: { en: 'Deputy Chair Summary' },
  DEPUTYMEMBER: { en: 'Deputy Member Summary' },
  OBSERVER: { en: 'Observer Summary' },
  SHAREHOLDER: { en: 'Investor Summary' },
  AUDITOR: { en: 'Auditor Summary' },
  CONTACTPERSON: { en: 'Contact Person Summary' },
  SHAREHOLDER_PROXY: { en: 'Share Holder Proxy Summary' },
  BUYER: { en: 'Buyer Summary' },
  SCHOOL: { en: 'Boardschool user Summary' },
  OWNER: { en: 'Owner' },
  ORGBRAIN_SUPPORT: { en: 'Orgbrain Support' },
};

export function getFriendlyRoleName(role: Role) {
  return roleSummaryDescriptionList[role].en;
}

export type RoleMapType = { [role in Role]: OptionalPermissionBooleanMap };

export enum PermissionEnum {
  ADMIN = 'ADMIN',
  MEETING_ADMIN = 'MEETING_ADMIN',
  USERMANAGER = 'USERMANAGER',
  GA_MEETING_ADMIN = 'GA_MEETING_ADMIN',
  BOARD_MEETING_ADMIN = 'BOARD_MEETING_ADMIN',
  SHAREHOLDER_ADMIN = 'SHAREHOLDER_ADMIN',
  TASKS_ADMIN = 'TASKS_ADMIN',
  NEWS_ADMIN = 'NEWS_ADMIN',
  GA_NEWS_ADMIN = 'GA_NEWS_ADMIN',
  CAN_VOTE = 'CAN_VOTE',
  CAN_VOTE_BOARDMEETING = 'CAN_VOTE_BOARDMEETING',

  ACCESS_TO_BOARD_MEETINGS = 'ACCESS_TO_BOARD_MEETINGS',
  ACCESS_TO_GA_MEETINGS = 'ACCESS_TO_GA_MEETINGS',

  DATAROOM_DOWNLOAD_ZIP = 'DATAROOM_DOWNLOAD_ZIP',
  ORGBRAIN_ACADEMY = 'ORGBRAIN_ACADEMY',
  TEMPLATES_ADMIN = 'TEMPLATES_ADMIN',
  DATAROOM_ADMIN = 'DATAROOM_ADMIN',
  DATAROOM_SEE_SHOW_DELETED = 'DATAROOM_SEE_SHOW_DELETED',
  DATAROOM_COPY_FILE = 'DATAROOM_COPY_FILE',
  DATAROOM_SEE_FILE_DETAILS = 'DATAROOM_SEE_FILE_DETAILS',
  DATAROOM_SEE_FILE_HISTORY = 'DATAROOM_SEE_FILE_HISTORY',
  DATAROOM_CREATE_TASK_FROM_FILE = 'DATAROOM_CREATE_TASK_FROM_FILE',
  DATAROOM_SEE_FILE_MENU = 'DATAROOM_SEE_FILE_MENU',
  DATAROOM_SET_FILE_STATUS = 'DATAROOM_SET_FILE_STATUS',
  SHAREHOLDER = 'SHAREHOLDER',
  OWNER = 'OWNER',
  VIEW_SHARES = 'VIEW_SHARES',
  CREATE_SIGNING_ORDER = 'CREATE_SIGNING_ORDER',
  SEE_CYCLIC_CALENDAR = 'SEE_CYCLIC_CALENDAR',
  SEE_FOLLOWUP_CASES = 'SEE_FOLLOWUP_CASES',
  SEE_ACTION_POINTS = 'SEE_ACTION_POINTS',
  SEE_USER_EMAILS = 'SEE_USER_EMAILS',
  SEE_MANAGE_SIGNATURES = 'SEE_MANAGE_SIGNATURES',
  SEE_USER_NAMES = 'SEE_USER_NAMES',
  SEE_ENTITY_SENSITIVE = 'SEE_ENTITY_SENSITIVE',
  BUYER = 'BUYER',
  SELLER = 'SELLER',
  BUYER_ADMIN = 'BUYER_ADMIN',
  SELLER_ADMIN = 'SELLER_ADMIN',
  ACCESS_TO_NEWS_FOR_BOARD = 'ACCESS_TO_NEWS_FOR_BOARD',
  ACCESS_TO_NEWS_FOR_SHAREHOLDERS = 'ACCESS_TO_NEWS_FOR_SHAREHOLDERS',
  OPENAI_USER = 'OPENAI_USER',
  shareholders = 'shareholders',
  BOARDMATCH_ADMIN = 'BOARDMATCH_ADMIN',
  templates = 'templates',
  files = 'files',
  news = 'news',
  surveys = 'surveys',
  meetings = 'meetings',
  tasks = 'tasks',
  school = 'school',
  chat = 'chat',
  organization = 'organization',
}

export enum PermissionSecurityEnum {
  SOFT = 'SOFT',
  HARD = 'HARD',
  MIXED = 'MIXED',
  UNUSED = 'UNUSED',
  UNKNOWN = 'UNKNOWN',
}

export enum PermissionLevelEnum {
  TOP = 'TOP',
  FEATURE = 'FEATURE',
  HIDDEN = 'HIDDEN',
}

export type PermissionDescription = {
  description: string;
  en: string;
  nb: string;
  group: string;
  security: PermissionSecurityEnum;
  level: PermissionLevelEnum;
  category: PermissionEnum;
  dependencies: PermissionEnum[];
  tooltips: CompanyTerminology;
};

export type PermissionDescriptionMap = {
  [perm in PermissionEnum]: PermissionDescription;
};

// The en and nb  descriptions should be useful to describe the file group for this permission and also give an idea of what the permission allow the user to do.
// the group is used internally to store the file permission

export const permissionDescription: PermissionDescriptionMap = {
  surveys: {
    description: 'Access to surveys',
    en: 'SURVEYS',
    nb: 'SPØRRESKJEMA',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.surveys,
    dependencies: [],
    tooltips: null,
  },

  meetings: {
    description: 'Access to meetings',
    en: 'MEETINGS',
    nb: 'MØTER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: null,
  },

  templates: {
    description: 'Access to templates',
    en: 'TEMPLATES',
    nb: 'TILGANG TIL MALER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.templates,
    dependencies: [],
    tooltips: null,
  },

  files: {
    description: 'Access to files',
    en: 'DATAROOM',
    nb: 'DATAROM',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  news: {
    description: 'Access to news/messages',
    en: 'MESSAGES',
    nb: 'MELDINGER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.news,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user has access to messages',
        nb: 'Brukeren har tilgang til meldinger',
      },
    },
  },

  school: {
    description: 'Access to boardschool',
    en: 'BOARDSCHOOL',
    nb: 'STYRESKOLEN',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.school,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user will have access to Orgbrain Board School',
        nb: 'Brukeren vil få tilgang til Orgbrain styreskole',
      },
    },
  },

  shareholders: {
    description: 'Access to shareholders',
    en: 'SHAREHOLDERS',
    nb: 'EIER',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.shareholders,
    dependencies: [PermissionEnum.VIEW_SHARES],
    tooltips: {
      default: {
        en: 'Shareholder module',
        nb: 'Shareholder module ',
      },
      BRL: {
        en: ' Members app',
        nb: ' Members app NB',
      },
    },
  },

  tasks: {
    description: 'Access to tasks',
    en: 'TASKS',
    nb: 'OPPGAVER',
    group: 'tasks',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.tasks,
    dependencies: [PermissionEnum.SEE_ACTION_POINTS],
    tooltips: null,
  },

  TASKS_ADMIN: {
    description: 'Managing tasks',
    en: 'Tasks admin',
    nb: 'Oppgaver admin',
    group: 'task_admin',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.tasks,
    dependencies: [PermissionEnum.SEE_ACTION_POINTS, PermissionEnum.SEE_FOLLOWUP_CASES],
    tooltips: null,
  },

  SEE_FOLLOWUP_CASES: {
    description: ' Used to show/hide followup cases in frontend and guard in backend. ',
    en: 'Access to followup cases',
    nb: 'Tilgang til oppfølgingssaker',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.tasks,
    dependencies: [],
    tooltips: null,
  },

  SEE_ACTION_POINTS: {
    description: ' Used to show/hide action points in frontend. ',
    en: 'See ordinary tasks',
    nb: 'Se ordinære oppgaver',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.tasks,
    dependencies: [],
    tooltips: null,
  },

  chat: {
    description: 'Access to chat',
    en: 'CHAT',
    nb: 'CHAT',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.chat,
    dependencies: [],
    tooltips: null,
  },
  organization: {
    description: 'Access to organization',
    en: 'ORGANIZATION',
    nb: 'ORGANISASJON',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.organization,
    dependencies: [],
    tooltips: null,
  },
  SHAREHOLDER: {
    description:
      "Used to give 'ga_meetings' file group. Also  used in checkUserHasShareholderAccess() which seems to allow empty array to be returned rather throw error for followup and action points",
    en: 'Investor',
    nb: 'Investor',
    group: 'ga_meetings',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
  },
  OWNER: {
    description:
      ' Used to turn on a unused flag in mainmenu.  We could probably remove this permission but may be useful. ',
    en: 'Owner',
    nb: 'Eier',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
  },
  VIEW_SHARES: {
    description: ' Backend guard  to view shares related stuff',
    en: 'View owner / member register',
    nb: 'Se eier-/medlemsregister',
    group: null,
    security: PermissionSecurityEnum.HARD,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user has access to view the shareholder register / member register',
        nb: 'Brukeren har tilgang til å se aksjeeierregisteret / medlemsregisteret',
      },
    },
  },
  ACCESS_TO_BOARD_MEETINGS: {
    description:
      "Used to give 'all_meeting' file group. Also guard for board meeting related stuff in backend.",
    en: 'Access to board meetings',
    nb: 'Tilgang til styremøter',
    group: 'all_meeting',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [PermissionEnum.SEE_FOLLOWUP_CASES],
    tooltips: {
      default: {
        en: 'The user has access to board meetings',
        nb: 'The user has access to board meetings',
      },
    },
  },
  ACCESS_TO_GA_MEETINGS: {
    description:
      "Used to give 'ga_meetings' file group. Also guard for GA meeting related stuff in backend. ",
    en: 'Access to GA meetings',
    nb: 'Generalforsamling/årsmøte-tilgang',
    group: 'ga_meetings',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [PermissionEnum.VIEW_SHARES],
    tooltips: {
      default: {
        en: 'The user has access to general assembly meetings / annual meetings',
        nb: 'Brukeren har tilgang til generalforsamlingsmøter / årsmøter',
      },
    },
  },
  CAN_VOTE_BOARDMEETING: {
    description: ' Used to set bm.votingright in frontend.',
    en: 'Board Meeting Voting rights',
    nb: 'Stemmerett',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [PermissionEnum.CAN_VOTE],
    tooltips: null,
  },
  CAN_VOTE: {
    description:
      'Guard in backend. See updateAgendaItemVotingAuth()  Bogdan tried to explain but I was too stupid. I think could be removed and replaced with CAN_VOTE_BOARDMEETING. ',
    en: 'Voting rights',
    nb: 'Stemmerett',
    group: null,
    security: PermissionSecurityEnum.HARD,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: {
      default: {
        en: 'The user always has the right to vote',
        nb: 'Brukeren har alltid stemmerett',
      },
    },
  },
  USERMANAGER: {
    description: ' Used for adding and managing user logic in frontend and backend ',
    en: 'Add/remove users',
    nb: 'Legge til/fjerne brukere',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.organization,
    dependencies: [],
    tooltips: {
      default: {
        en: 'User can add and remove other users',
        nb: 'Bruker kan legge til og fjerne andre brukere',
      },
    },
  },
  ADMIN: {
    description: ' Legacy ADMIN permission. ',
    en: 'Administrator',
    nb: 'Administrator',
    group: 'admin',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.ADMIN,
    dependencies: [
      //  Until we deprecated this permission automatically add all other admin permissions
      PermissionEnum.USERMANAGER,
      PermissionEnum.MEETING_ADMIN,
      PermissionEnum.GA_MEETING_ADMIN,
      PermissionEnum.BOARD_MEETING_ADMIN,
      PermissionEnum.SHAREHOLDER_ADMIN,
      PermissionEnum.TASKS_ADMIN,
      PermissionEnum.VIEW_SHARES,
      PermissionEnum.ACCESS_TO_BOARD_MEETINGS,
      PermissionEnum.NEWS_ADMIN,
      PermissionEnum.GA_NEWS_ADMIN,
      PermissionEnum.TEMPLATES_ADMIN,
      PermissionEnum.TEMPLATES_ADMIN,
    ],
    tooltips: {
      default: {
        en: 'The user has all administrator rights',
        nb: 'Brukeren har alle administratorrettigheter',
      },
    },
  },

  MEETING_ADMIN: {
    description:
      ' Legacy meeting admin permission.  You get this if you have GA_MEETING_ADMIN or BOARD_MEETING_ADMIN. ',
    en: 'MeetingAdministrator',
    nb: 'MeetingAdministrator',
    group: 'meeting_admin',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: null,
  },

  GA_MEETING_ADMIN: {
    description: ' Turns on meeting creation stuff in frontend available for GA meetings.',
    en: 'General meeting admin',
    nb: 'Generalforsamling/årsmøte-admin',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [
      PermissionEnum.MEETING_ADMIN,
      PermissionEnum.VIEW_SHARES,
      PermissionEnum.ACCESS_TO_GA_MEETINGS,
    ],
    tooltips: null,
  },

  BOARD_MEETING_ADMIN: {
    description: ' Turns on meeting creation stuff in frontend  available for Board meetings..',
    en: 'Board meeting admin',
    nb: 'Styremøte-admin',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.meetings,
    dependencies: [
      PermissionEnum.MEETING_ADMIN,
      PermissionEnum.ACCESS_TO_BOARD_MEETINGS,
      PermissionEnum.SEE_FOLLOWUP_CASES,
    ],
    tooltips: null,
  },

  SHAREHOLDER_ADMIN: {
    description:
      ' Gives shareholder manager access. Create/edit :  entities / shares / BeneficialOwners',
    en: 'Shareholder Administrator',
    nb: 'Eier-administrator',
    group: null,
    security: PermissionSecurityEnum.UNKNOWN,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.shareholders,
    dependencies: [
      PermissionEnum.SEE_ENTITY_SENSITIVE,
      PermissionEnum.VIEW_SHARES,
      PermissionEnum.SEE_ENTITY_SENSITIVE,
    ],
    tooltips: null,
  },

  CREATE_SIGNING_ORDER: {
    description: ' Allows user to create signing orders in frontend. ',
    en: 'Can create signing orders',
    nb: 'Kan opprette signeringsordrer',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.CREATE_SIGNING_ORDER,
    dependencies: [],
    tooltips: null,
  },

  SEE_CYCLIC_CALENDAR: {
    description: ' Used to show/hide cyclic calendar in frontend. ',
    en: 'Access to cyclic calendar',
    nb: 'Tilgang til årshjulsvisning',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.meetings,
    dependencies: [],
    tooltips: null,
  },

  SEE_USER_EMAILS: {
    description: ' Used to show/hide user emails in frontend and guard in backend. ',
    en: 'See user emails',
    nb: 'Se brukers e-poster',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SEE_USER_EMAILS,
    dependencies: [],
    tooltips: null,
  },

  SEE_USER_NAMES: {
    description: ' Used to show/hide user names in frontend. ',
    // To make submenu item visible
    en: 'See user names',
    nb: 'Se brukernav',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SEE_USER_NAMES,
    dependencies: [],
    tooltips: null,
  },

  SEE_MANAGE_SIGNATURES: {
    // Makes sub menu item visible
    description: ' Used to show/hide manage signatures in frontend. ',
    en: 'See manage signatures',
    nb: 'Se administrer underskrifter',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.SEE_MANAGE_SIGNATURES,
    dependencies: [],
    tooltips: null,
  },

  SEE_ENTITY_SENSITIVE: {
    description: ' Used to show/hide entity sensitive data in frontend and backend guard. ',
    en: 'SEE_ENTITY_SENSITIVE',
    nb: 'SEE_ENTITY_SENSITIVE',
    group: null,
    security: PermissionSecurityEnum.UNKNOWN,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.shareholders,
    dependencies: [],
    tooltips: null,
  },

  ACCESS_TO_NEWS_FOR_BOARD: {
    description: " Used here permisionGroupsAndRoles.  I don't understand ask Bogdan   ",
    en: 'Access to news for board',
    nb: 'Tilgang til meldinger for styret',
    group: null,
    security: PermissionSecurityEnum.UNKNOWN,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.news,
    dependencies: [],
    tooltips: {
      default: {
        en: 'ACCESS_TO_NEWS_FOR_BOARD',
        nb: 'ACCESS_TO_NEWS_FOR_BOARD',
      },
    },
  },

  ACCESS_TO_NEWS_FOR_SHAREHOLDERS: {
    description: " Used here permisionGroupsAndRoles.  I don't understand ask Bogdan   ",
    en: 'Access to news for shareholders',
    nb: 'Tilgang til meldinger for aksjeeiere',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.news,
    dependencies: [],
    tooltips: {
      default: {
        en: 'ACCESS_TO_NEWS_FOR_SHAREHOLDERS',
        nb: 'ACCESS_TO_NEWS_FOR_SHAREHOLDERS',
      },
    },
  },

  BUYER_ADMIN: {
    description: " Used to give file group 'buyer_admin'",
    en: 'Buyer admin',
    nb: 'Kjøper-admin',
    group: 'buyer_admin',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.BUYER_ADMIN,
    dependencies: [],
    tooltips: null,
  },

  SELLER_ADMIN: {
    description: " Used to give file group 'seller_admin'",
    en: 'Seller admin',
    nb: 'Selger-admin',
    group: 'seller_admin',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SELLER_ADMIN,
    dependencies: [],
    tooltips: null,
  },

  BUYER: {
    description: " Used to give file group 'buyer'",
    en: 'Buyer',
    nb: 'Kjøper-innsyn',
    group: 'buyer',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.BUYER,
    dependencies: [],
    tooltips: null,
  },

  SELLER: {
    description: " Used to give file group 'seller'",
    en: 'Seller',
    nb: 'Selger',
    group: 'seller',
    security: PermissionSecurityEnum.UNUSED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.SELLER,
    dependencies: [],
    tooltips: null,
  },

  DATAROOM_DOWNLOAD_ZIP: {
    description: ' Used to show/hide download zip in frontend. Backend guard.',
    en: 'Dataroom zip',
    nb: 'Dataroom zip',
    group: null,
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  ORGBRAIN_ACADEMY: {
    description: ' TOP SECRET ',
    en: 'ORGBRAIN ACADEMY',
    nb: 'ORGBRAIN ACADEMY',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.ORGBRAIN_ACADEMY,
    dependencies: [],
    tooltips: null,
  },

  TEMPLATES_ADMIN: {
    description: ' Allow admin for tempalates',
    en: 'Templates admin',
    nb: 'Maler admin',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.templates,
    dependencies: [],
    tooltips: null,
    group: null,
  },

  DATAROOM_SEE_SHOW_DELETED: {
    description: ' Used to show/hide show deleted in frontend.',
    en: 'See "show deleted"',
    nb: 'Se "vis slettet"',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  DATAROOM_COPY_FILE: {
    description: ' Used to show/hide copy in frontend.',
    en: 'Copy file',
    nb: 'Kopier fil',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  DATAROOM_SEE_FILE_DETAILS: {
    description: ' Used to show/hide file details in frontend.',
    en: 'See file details',
    nb: 'Se fildetaljer',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },
  DATAROOM_SEE_FILE_HISTORY: {
    description: ' Used to show/hide file history in frontend.',
    en: 'See file history',
    nb: 'Se filhistorikk',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  DATAROOM_CREATE_TASK_FROM_FILE: {
    description: ' Used to show/hide create task from file in frontend.',
    en: 'Create task from a file',
    nb: 'Lag oppgave fra en fil',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  DATAROOM_SEE_FILE_MENU: {
    description: ' Used to show/hide file menu in frontend.',
    en: 'See file menu',
    nb: 'Se filmenyen',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  DATAROOM_SET_FILE_STATUS: {
    description: ' Used to show/hide set file status in frontend.',
    en: 'Set file status',
    nb: 'Angi filstatus',
    group: null,
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.HIDDEN,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: null,
  },

  NEWS_ADMIN: {
    description: ' Used to show/hide news admin features in frontend.',
    en: 'Messages admin',
    nb: 'Meldinger-admin',
    group: 'news_admin',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.news,
    dependencies: [PermissionEnum.news],
    tooltips: null,
  },
  GA_NEWS_ADMIN: {
    description: ' Turns on meeting creation stuff in frontend available for GA meetings.',
    en: 'GA Messages admin',
    nb: 'GF Meldinger-admin',
    group: 'ga_news_admin',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.news,
    dependencies: [PermissionEnum.news],
    tooltips: null,
  },
  [PermissionEnum.DATAROOM_ADMIN]: {
    description: ' Allow admin of data room',
    en: 'Dataroom admin',
    nb: 'Dataroom admin',
    group: '',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.FEATURE,
    category: PermissionEnum.files,
    dependencies: [],
    tooltips: undefined,
  },
  [PermissionEnum.OPENAI_USER]: {
    description: 'openai access',
    en: 'openai access',
    nb: 'openai access',
    group: '',
    security: PermissionSecurityEnum.MIXED,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.OPENAI_USER,
    dependencies: [],
    tooltips: undefined,
  },
  [PermissionEnum.BOARDMATCH_ADMIN]: {
    description: 'Boardmatch admin',
    en: 'Boardmatch admin',
    nb: 'Boardmatch admin',
    group: '',
    security: PermissionSecurityEnum.SOFT,
    level: PermissionLevelEnum.TOP,
    category: PermissionEnum.BOARDMATCH_ADMIN,
    dependencies: [],
    tooltips: undefined,
  },
};

const reverseMapGroupKeyToPermissionTemp = {};
for (const key of Object.keys(permissionDescription)) {
  if (permissionDescription[key]?.group) {
    reverseMapGroupKeyToPermissionTemp[permissionDescription[key].group] =
      reverseMapGroupKeyToPermissionTemp[permissionDescription[key].group] || [];
    reverseMapGroupKeyToPermissionTemp[permissionDescription[key].group].push(key);
  }
}
export const reverseMapGroupKeyToPermission = reverseMapGroupKeyToPermissionTemp;

export const groupDescription: {
  [group: string]: PermissionDescriptionMap;
} = Object.keys(permissionDescription).reduce((acc, key) => {
  const item = permissionDescription[key];
  if (item.group) {
    acc[item.group] = item;
  }
  return acc;
}, {}) as any;

export type CompanyTerminology = {
  [orgtype in CompanyTerminologyEnum]?: { en: string; nb: string };
};
export type PermissionDescriptionTooltips = {
  [key in PermissionEnum]: CompanyTerminology;
};

export const permissionDescriptionTooltips: PermissionDescriptionTooltips = {} as any;

for (const key of Object.keys(PermissionEnum)) {
  const perm = PermissionEnum[key];
  const permDesc = permissionDescription[perm];
  if (permDesc && permDesc.tooltips) {
    permissionDescriptionTooltips[perm] = permDesc.tooltips;
  }
}

export const group2Enum: { [group: string]: PermissionEnum } = {};

for (const permEnum of Object.keys(permissionDescription)) {
  const group: string = permissionDescription[permEnum].group;
  if (group) {
    group2Enum[group] = PermissionEnum[permEnum];
  }
}

export const FULL_PERMISSIONS: PermissionBooleanMap = Object.keys(PermissionEnum).reduce(
  (acc, item) => {
    acc[item] = true;
    return acc;
  },
  {}
) as PermissionBooleanMap;

export const FULL_ROLES: RoleBooleanMap = Object.keys(Role).reduce((acc, item) => {
  acc[item] = true;
  return acc;
}, {}) as RoleBooleanMap;

//
export const DD_ONLY_ROLES: OptionalRoleBooleanMap = {
  SELLER: true,
  BUYER: true,
  SELLER_ADMIN: true,
  BUYER_ADMIN: true,
};

export const DD_ONLY_PERMISSIONS: OptionalRoleBooleanMap = {
  SELLER: true,
  BUYER: true,
  SELLER_ADMIN: true,
  BUYER_ADMIN: true,
};

export type PermissionDependancyArrayMap = { [perm in PermissionEnum]?: PermissionEnum[] };
export type PermissionDependancyMap = {
  // for convience in front end we convert to this
  [perm in PermissionEnum]?: { [perm in PermissionEnum]?: boolean };
};

export const _permissionDependancyArrayMap: PermissionDependancyArrayMap = {};

for (const perm of Object.keys(permissionDescription)) {
  const permEnum = PermissionEnum[perm];
  const deps = permissionDescription[permEnum].dependencies;
  if (deps) {
    _permissionDependancyArrayMap[permEnum] = deps;
  }
}

for (const key of Object.keys(_permissionDependancyArrayMap)) {
  const deps = _permissionDependancyArrayMap[key];

  for (const perm of deps) {
    if (permissionDescription[perm].level !== PermissionLevelEnum.HIDDEN) {
      // console.error(` ${key}  depends on FEATURE  ${perm}`);
    }
  }
}
// These are used to hide roles or permission in the UI.

export const hiddenRoles: OptionalRoleBooleanMap = {
  CONTACTPERSON: true,
  ORGBRAIN_SUPPORT: true,
  // ROLE_1: true,
  // ROLE_2: true,
  // ROLE_3: true,
  // ROLE_4: true,
  // ROLE_5: true,
  // ROLE_6: true,
};

export const hiddenPermissions: OptionalPermissionBooleanMap = {};

for (const key of Object.keys(permissionDescription)) {
  if (permissionDescription[key].level === PermissionLevelEnum.HIDDEN) {
    hiddenPermissions[key] = true;
  }
}
