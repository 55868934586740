export const textMappings = {
  Reminder: {
    en: 'Reminder',
    nb: 'Påminnelse',
    sv: 'Påminnelse',
  },

  and: {
    en: 'and',
    nb: 'og',
    sv: 'och',
  },
  of: {
    en: 'of',
    nb: 'av',
    sv: 'av',
  },
  Page: {
    en: 'Page',
    nb: 'Side',
    sv: 'Sida',
  },
  Pages: {
    en: 'Pages',
    nb: 'Sider',
    sv: 'Sidor',
  },
  'This document package contains': {
    en: 'This document package contains',
    nb: 'Denne dokumentpakken inneholder',
  },
  'Signing of encrypted files is not supported': {
    en: 'Signing of encrypted files is not supported',
    nb: 'Signering av kryptert fil støttes ikke',
  },
  'The original document': {
    en: 'The original document',
    nb: 'Det originale dokumentet',
  },
  'This signature page at the end': {
    en: 'This signature page at the end',
    nb: 'Denne signatursiden på slutten',
  },
  'Signature pages at the end': {
    en: 'Signature pages at the end',
    nb: 'Signatur sidene på slutten',
  },
  'COUNTRY:NO': {
    en: 'Norway',
    nb: 'Norge',
    sv: 'Norge',
  },
  'COUNTRY:SE': {
    en: 'Sweden',
    nb: 'Sverige',
    sv: 'Sverige',
  },
  'COUNTRY:DK': {
    en: 'Denmark',
    nb: 'Danmark',
    sv: 'Danmark',
  },
  EMAIL_ATTACHMENTS: {
    en: 'Email attachments',
    nb: 'E-postvedlegg',
    sv: 'E-postbilagor',
  },
  PROXY_FOLDER: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  MINUTES: {
    en: 'Minutes',
    nb: 'Protokoll',
    sv: 'Protokoll',
  },
  ATTACHMENTS: {
    en: 'Attachments',
    nb: 'Vedlegg',
    sv: 'Bilagor',
  },
  MINUTES_ATTACHMENTS: {
    en: 'Minutes attachments',
    nb: 'Vedlegg til protokoll',
    sv: 'Bilagor till protokoll',
  },
  DRAFT: {
    en: 'Draft',
    nb: 'Kladd',
    sv: 'Förslag',
  },
  PLANNED: {
    en: 'Planned',
    nb: 'Planlagt',
    sv: 'Planerad',
  },
  CALLED: {
    en: 'Called',
    nb: 'Innkalt',
    sv: 'Kallad',
  },
  ACTIVE: {
    en: 'Active',
    nb: 'Aktivt',
    sv: 'Aktiva',
  },
  ANNUAL_PLAN: {
    en: 'Annual plan',
    nb: 'Årsplan',
    sv: 'Årlig plan',
  },
  REQUIRES_MINUTES: {
    en: 'Requires minutes',
    nb: 'Mangler protokoll',
    sv: 'Kräver protokoll',
  },
  REQUIRES_DISCUSSION: {
    en: 'Requires comments',
    nb: 'Protokoll på høring',
    sv: 'Protokoll från förhandlingen',
  },
  REQUIRES_SIGNATURES: {
    en: 'Signing',
    nb: 'Signering',
    sv: 'Signering',
  },
  COMPLETED: {
    en: 'Completed',
    nb: 'Fullført',
    sv: 'Färdigt',
  },
  CANCELLED: {
    en: 'Cancelled',
    nb: 'Avlyst',
    sv: 'Inställt',
  },
  TEST: {
    en: 'Trial',
    nb: 'Prøve',
    sv: 'Rättegång',
  },
  BOARDSCHOOL: {
    en: 'Board School',
    nb: 'Styreskolen',
    sv: 'Styrelseskolan',
  },
  SUSPENDED: {
    en: 'Expired',
    nb: 'Utgått',
    sv: 'Utgånget',
  },
  GRUNDER: {
    en: 'Founder',
    nb: 'Grunder',
    sv: 'Grundare',
  },
  SMALL_BUSINESS: {
    en: 'Small business',
    nb: 'Liten bedrift',
    sv: 'Litet företag',
  },
  FOUNDER: {
    en: 'Founder package',
    nb: 'Gründerpakke',
    sv: 'Grundarpaket',
  },
  SMALL_BUSINESS2: {
    en: 'Small business package',
    nb: 'Liten bedrift-pakke',
    sv: 'Småföretagspaket',
  },
  BASIS: {
    en: 'Standard',
    nb: 'Standard',
    sv: 'Standard',
  },
  ENTERPRISE: {
    en: 'Enterprise',
    nb: 'Enterprise',
    sv: 'Företag',
  },
  PersonalFiles: {
    en: 'Personal files',
    nb: 'Personlige filer',
    sv: 'Personliga filer',
  },
  MeetingFiles: {
    en: 'Meeting files',
    nb: 'Møtefiler',
    sv: 'Mötesfiler',
  },
  all_meeting: {
    en: 'Board',
    nb: 'Styre',
    sv: 'Styrelse',
  },
  ga_meetings: {
    en: 'Owner/Member',
    nb: 'Eier/Medlem',
    sv: 'Ägare / medlem',
  },
  admin: {
    en: 'Admin',
    nb: 'Admin',
    sv: 'Administration',
  },
  buyer: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },
  DataRoomRoot: {
    en: 'Data Room',
    nb: 'Datarom',
    sv: 'Datarum',
  },

  Enable: {
    en: 'Enable',
    nb: 'Aktiver',
    sv: 'Gör det möjligt',
  },
  Disable: {
    en: 'Disable',
    nb: 'Deaktiver',
    sv: 'Inaktivera',
  },
  Enabled: {
    en: 'Enabled',
    nb: 'Aktivert',
    sv: 'Aktiverad',
  },
  Disabled: {
    en: 'Disabled',
    nb: 'Deaktivert',
    sv: 'Inaktiverad',
  },
  Yes: {
    en: 'Yes',
    nb: 'Ja',
    sv: 'Ja',
  },
  No: {
    en: 'No',
    nb: 'Nei',
    sv: 'Nej',
  },
  'Meeting type': {
    en: 'Meeting type',
    nb: 'Møtetype',
    sv: 'Möte typ',
  },
  PHYSICAL_MEETING: {
    en: 'Physical meeting',
    nb: 'Fysisk møte',
    sv: 'Fysiskt möte',
  },
  PHONE_MEETING: {
    en: 'Phone meeting',
    nb: 'Telefonmøte',
    sv: 'Telefonkonferens',
  },
  HYBRID_MEETING: {
    en: 'Hybrid meeting',
    nb: 'Hybridmøte',
    sv: 'Hybrid möte',
  },
  DIGITAL_MEETING: {
    en: 'Digital meeting',
    nb: 'Digitalt møte',
    sv: 'Digital möte',
  },
  VIDEO_CONFERENCE: {
    en: 'Video conference',
    nb: 'Videokonferanse',
    sv: 'Videokonferens',
  },
  WRITTEN_PROCEDURE: {
    en: 'Written procedure',
    nb: 'Skriftlig behandling',
    sv: 'Skriftligt förfarande',
  },
  OTHER_PROCEDURE: {
    en: 'Other procedure',
    nb: 'Annen møteform',
    sv: 'Annat förfarande',
  },
  Date: {
    en: 'Date',
    nb: 'Dato',
    sv: 'Datum',
  },
  Location: {
    en: 'Location',
    nb: 'Sted',
    sv: 'Plats',
  },
  'Proposed resolution': {
    en: 'Proposed resolution',
    nb: 'Forslag til vedtak',
    sv: 'Föreslagen resolution',
  },
  For: {
    en: 'For',
    nb: 'For',
    sv: 'För',
  },
  Against: {
    en: 'Against',
    nb: 'Imot',
    sv: 'Mot',
  },
  'General comments': {
    en: 'General comments',
    nb: 'Generelle kommentarer',
    sv: 'Generella kommentarer',
  },
  Participants: {
    en: 'Participants',
    nb: 'Deltakere',
    sv: 'Deltagarna',
  },
  'Did not participate': {
    en: 'Did not participate',
    nb: 'Deltok ikke',
    sv: 'Deltog inte',
  },
  'Chair of the meeting': {
    en: 'Chair of the meeting',
    nb: 'Møteleder',
    sv: 'Ordförande för mötet',
  },
  'In the event of a tie, the decision is made by a built-in lottery': {
    en: 'In the event of a tie, the decision is made by a built-in lottery',
    nb: 'Ved stemmelikhet, treffes avgjørelsen ved en innebygget loddtrekning',
    sv: 'I händelse av oavgjort fattas beslutet av ett inbyggt lotteri',
  },
  minutesInfoForProcedureTypePhysicalMeeting: {
    en: 'The proceedings were carried out as a meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et møte.',
    sv: 'Förfarandet genomfördes som ett möte.',
  },
  minutesInfoForProcedureTypeHybridMeeting: {
    en: 'The proceedings were carried out as a hybrid meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et hybridmøte.',
    sv: 'Förfarandet genomfördes som ett hybridmöte.',
  },
  minutesInfoForProcedureTypeDigitalMeeting: {
    en: 'The proceedings were carried out as a digital meeting.',
    nb: 'Styrebehandlingen ble gjennomført som et digitalt møte.',
    sv: 'Förfarandet genomfördes som ett digital möte.',
  },

  minutesInfoForProcedureTypePhoneMeeting: {
    en: 'The proceedings were carried out as a phone meeting.',
    nb: 'Styrebehandlingen ble gjennomført per telefon.',
    sv: 'Förfarandet genomfördes som ett telefonmöte.',
  },
  minutesInfoForProcedureTypeVideoConference: {
    en: 'The proceedings were carried out as a video conference.',
    nb: 'Styrebehandlingen ble gjennomført som en videokonferanse.',
    sv: 'Förfarandet genomfördes som en videokonferens.',
  },
  minutesInfoForProcedureTypeWrittenProcedure: {
    en: 'The proceedings were carried out in writing without a meeting.',
    nb: 'Styrebehandlingen ble gjennomført skriftlig og uten møte.',
    sv: 'Förfarandet genomfördes skriftligen utan möte.',
  },
  MeetingVotedOnAndApproved: {
    en: 'The following decision was voted on and approved:',
    nb: 'Følgende forslag ble stemt over og vedtatt:',
    sv: 'Följande beslut röstades om och godkändes:',
  },
  MeetingVotedOnAndRejected: {
    en: 'The following decision was voted on but rejected:',
    nb: 'Følgende forslag ble stemt over, men fikk ikke flertall:',
    sv: 'Följande beslut röstades om men avvisades:',
  },
  MeetingVotePassedAllFor: {
    en: 'There was a unanimous vote in favour of the following decision:',
    nb: 'Følgende forslag ble enstemmig vedtatt:',
    sv: 'Följande beslut röstades enhälligt för:',
  },
  MeetingVotePassedWithAllDecisionsUnanimous: {
    en: 'The following decision was made:',
    nb: 'Det ble fattet følgende vedtak:',
    sv: 'Följande beslut fattades:',
  },
  MeetingVoteRjectedAllAgainst: {
    en: 'There was a unanimous vote against the following decision:',
    nb: 'Følgende forslag ble enstemmig nedstemt:',
    sv: 'Följande beslut röstades enhälligt emot:',
  },
  MeetingThoseForDecision: {
    en: 'The following voted in favour of the decision:',
    nb: 'Følgende stemte for forslaget:',
    sv: 'Följande röstade för beslutet:',
  },
  MeetingThoseAgainstDecision: {
    en: 'The following voted against the decision:',
    nb: 'Følgende stemte imot forslaget:',
    sv: 'Följande röstade emot beslutet:',
  },
  MeetingThoseNeutralDecision: {
    en: 'The following voted neutral:',
    nb: 'Følgende avga ikke stemme:',
    sv: 'Följande röstade neutralt:',
  },
  MeetingAllUnanimousStatement: {
    en: 'All decisions were passed unanimously.',
    nb: 'Det var enstemmighet i alle avstemningene.',
    sv: 'Alla beslut fattades enhälligt.',
  },
  TheFollowingMustSignRead: {
    en: 'The following must sign that the minutes have been read:',
    nb: 'Følgende skal signere at protokollen er lest:',
    sv: 'Följande måste underteckna att protokollet har lästs:',
  },
  theFollowingWasApproved: {
    en: 'The following was approved',
    nb: 'Følgende ble vedtatt',
    sv: 'Följande godkändes',
  },
  theFollowingWasRejected: {
    en: 'The following was rejected',
    nb: 'Følgende ble nedstemt',
    sv: 'Följande avvisades',
  },
  overviewOfVoting: {
    en: 'Overview of voting',
    nb: 'Oversikt over stemmegiving',
    sv: 'Översikt över omröstning',
  },
  Case: {
    en: 'Case',
    nb: 'Sak',
    sv: 'Fall',
  },
  Shares: {
    en: 'Shares',
    nb: 'Aksjer',
    sv: 'Aktier',
  },
  'In person': {
    en: 'In person',
    nb: 'Personlig',
    sv: 'I person',
  },
  Proxy: {
    en: 'Proxy',
    nb: 'Fullmektig',
    sv: 'Ombud',
  },
  'Represented by': {
    en: 'Represented by',
    nb: 'Representert ved',
    sv: 'Representerad av',
  },
  'Follow-up case': {
    en: 'Follow-up case',
    nb: 'Oppfølgingssak',
    sv: 'Uppföljningsfall',
  },
  Description: {
    en: 'Description',
    nb: 'Beskrivelse',
    sv: 'Beskrivning',
  },
  'Responsible for follow-up': {
    en: 'Responsible for follow-up',
    nb: 'Ansvarlig for oppfølging',
    sv: 'Ansvarig för uppföljning',
  },
  'Follow-up date': {
    en: 'Follow-up date',
    nb: 'Oppfølgingsdato',
    sv: 'Uppföljningsdatum',
  },
  'By drawing lots': {
    en: 'By drawing lots',
    nb: 'Ved loddtrekning',
    sv: 'Genom att dra lod',
  },
  Elected: {
    en: 'Elected',
    nb: 'Valgt',
    sv: 'Vald',
  },
  'Elected candidates': {
    en: 'Elected candidates',
    nb: 'Valgte kandidater',
    sv: 'Valda kandidater',
  },
  'Elected candidate': {
    en: 'Elected candidate',
    nb: 'Valgt kandidat',
    sv: 'Vald kandidat',
  },
  orderOfCandidatesWSithSameNumberOfVotesDecidedRandomly: {
    en: 'The order of candidates with the same number of votes was decided by drawing lots.',
    nb: 'Rekkefølgen på kandidatene med likt antall stemmer er avgjort av systemet ved en tilfeldig loddtrekning.',
    sv: 'Ordningen på kandidater med samma antal röster bestämdes genom att dra lod.',
  },
  'On election': {
    en: 'On election',
    nb: 'På valg',
    sv: 'Vid valet',
  },
  position_electionResults: {
    en: 'Place',
    nb: 'Plass',
    sv: 'Placera',
  },
  Votes: {
    en: 'Votes',
    nb: 'Stemmer',
    sv: 'Röster',
  },
  'of votes represented': {
    en: 'of votes represented',
    nb: 'av stemmer representert',
    sv: 'av de representerade rösterna',
  },
  Candidate: {
    en: 'Candidate',
    nb: 'Kandidat',
    sv: 'Kandidat',
  },
  'Votes cast': {
    en: 'Votes cast',
    nb: 'Stemmer avgitt',
    sv: 'Avgivna röster',
  },
  'Votes for': {
    en: 'Votes for',
    nb: 'Stemmer for',
    sv: 'Röstar på',
  },
  'Votes against': {
    en: 'Votes against',
    nb: 'Stemmer imot',
    sv: 'Röstar emot',
  },
  'of votes cast': {
    en: 'of votes cast',
    nb: 'av stemmer avgitt',
    sv: 'av avgivna röster',
  },
  'of cast': {
    en: 'of cast',
    nb: 'av avgitt',
    sv: 'av avgivna',
  },
  'Total represented': {
    en: 'Total represented',
    nb: 'Totalt representert',
    sv: 'Totalt representerat',
  },
  Abstained: {
    en: 'Abstained',
    nb: 'Avstått',
    sv: 'Avstod',
  },
  Result: {
    en: 'Result',
    nb: 'Resultat',
    sv: 'Resultat',
  },
  Approved: {
    en: 'Approved',
    nb: 'Vedtatt',
    sv: 'Godkänd',
  },
  Rejected: {
    en: 'Rejected',
    nb: 'Nedstemt',
    sv: 'avvisade',
  },
  voteRuleExplanationStatutoryChange: {
    en: 'in accordance with majority requirement rules',
    nb: 'i overenstemmelse med flertallskrav',
    sv: 'i enlighet med regler för majoritetskrav',
  },
  voteRuleExplanationQualifiedMajority: {
    en: 'in accordance with qualified majority requirement',
    nb: 'i overenstemmelse med krav til kvalifisert flertall',
    sv: 'i enlighet med kvalificerad majoritet',
  },
  voteRuleExplanationUnanimity: {
    en: 'in accordance with unanimity requirement',
    nb: 'i overenstemmelse med krav til enstemmighet',
    sv: 'i enlighet med enhällighetskrav',
  },
  voteRuleExplanationInvestigation: {
    en: 'in accordance with vote approval requirement for proposition for investigation cases',
    nb: 'i overenstemmelse med stemmekrav for forslag om gransking',
    sv: 'i enlighet med kravet på röstgodkännande för förslag till utredningsärenden',
  },
  'Represented directly': {
    en: 'Represented directly',
    nb: 'Representert direkte',
    sv: 'Representeras direkt',
  },
  'Represented by pre-voting': {
    en: 'Represented by pre-voting',
    nb: 'Representert ved forhåndsstemme',
    sv: 'Representeras av förhandsröstning',
  },
  'Represented by joining': {
    en: 'Represented by joining',
    nb: 'Representert ved oppmøte',
    sv: 'Representeras genom att gå med',
  },
  'Voting shares': {
    en: 'Voting shares',
    nb: 'Stemmeberettigede aksjer',
    sv: 'Röstningsandelar',
  },
  'of voting-eligible shares': {
    en: 'of voting-eligible shares',
    nb: 'av stemme&shy;berettigede aksjer',
    sv: 'av röstberättigade aktier',
  },
  'own shares': {
    en: 'own shares',
    nb: 'egne aksjer',
    sv: 'egna aktier',
  },
  'Represented by proxy': {
    en: 'Represented by proxy',
    nb: 'Representert ved fullmakt',
    sv: 'Representeras av ombud',
  },
  'Reminder of meeting for': {
    en: 'Reminder of meeting for',
    nb: 'Påminnelse om møte for',
    sv: 'Påminnelse om möte för',
  },
  'New subgroup': {
    en: 'New subgroup',
    nb: 'Ny undergruppe',
    sv: 'Ny undergrupp',
  },
  MinutesFrom: {
    en: 'Minutes from',
    nb: 'Protokoll fra',
    sv: 'Protokoll från',
  },
  Recipients: {
    en: 'Recipients',
    nb: 'Mottakere',
    sv: 'Mottagare',
  },
  'AllRequirementsFullfilled?companyType=FLI': {
    en: 'All the formal requirements for notice and number of attendance are fulfilled.',
    nb: 'Alle de formelle krav til innkalling og antall deltagere er oppfylt.',
    sv: 'Alla formella krav för uppsägning och antal närvaro är uppfyllda.',
  },
  'AllRequirementsFullfilled?jurisdiction=NO': {
    en: 'The proceeding fulfills the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?companyType=ASA': {
    en: 'The proceeding fulfills the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsFullfilled?jurisdiction=EN': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen.',
  },
  'AllRequirementsFullfilled?jurisdiction=SE': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsFullfilled?jurisdiction=DK': {
    en: 'The proceeding fulfills the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller kraven i aktiebolagslagen § 124.',
  },
  'AllRequirementsNotFullfilled?companyType=FLI': {
    en: 'The formal requirements for notice and/or number of attendance are not fulfilled.',
    nb: 'De formelle krav til innkalling og/eller antall deltagere er ikke oppfylt.',
    sv: 'De formella kraven för anmälan och / eller antal närvaro uppfylls inte.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=NO': {
    en: 'The proceeding does not meet the requirements presented in Norwegian Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i norska aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?companyType=ASA': {
    en: 'The proceeding does not meet the requirements presented in the Public Limited Liability Companies Act § 6-24.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i allmennaksjeloven § 6-24.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 6-24.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=EN': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aksjeloven.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=SE': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act ch.8 § 21.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i aktiebolagslagen 8 kap. 21 §.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen kap. 8 § 21.',
  },
  'AllRequirementsNotFullfilled?jurisdiction=DK': {
    en: 'The proceeding does not meet the requirements presented in the Companies Act § 124.',
    nb: 'Saksbehandlingen oppfyller ikke kravene i selskabsloven § 124.',
    sv: 'Förfarandet uppfyller inte kraven i aktiebolagslagen § 124.',
  },
  'There is quorum, decisions can be taken': {
    en: 'There is quorum, decisions can be taken',
    nb: 'Styret er beslutningsdyktig',
    sv: 'Det finns beslutsförmåga, beslut kan fattas',
  },
  'There is not a quorum, decisions cannot be taken': {
    en: 'There is not a quorum, decisions cannot be taken',
    nb: 'Styret er ikke beslutningsdyktig',
    sv: 'Det finns inte beslutsfattande, beslut kan inte fattas',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=NO': {
    en: 'There was a quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv: 'Det var beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?companyType=ASA': {
    en: 'There was a quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=EN': {
    en: 'There was a quorum, and the requirements listed in the Companies Act for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det var beslutsfattande och kraven i företagslagen för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=SE': {
    en: 'There was a quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen kap. 8 § 21 för att fatta beslut uppfylldes.',
  },
  'ThereWasQuorumMinutesPdf?jurisdiction=DK': {
    en: 'There was a quorum, and the requirements listed in the Companies Act § 124 for making decisions were met.',
    nb: 'Styret var beslutningsdyktig i henhold til selskabsloven § 124.',
    sv: 'Det var beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut uppfylldes.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=NO': {
    en: 'There was no quorum, and the requirements listed in Norwegian Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven § 6-24.',
    sv: 'Det fanns inget beslutsfattande och kraven i norska aktiebolagslagen § 6-24 för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?companyType=ASA': {
    en: 'There was no quorum, and the requirements listed in the Public Limited Liability Companies Act § 6-24 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til allmennaksjeloven § 6-24.',
    sv: 'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 6-24 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=EN': {
    en: 'There was no quorum, and the requirements listed in the Companies Act for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aksjeloven.',
    sv: 'Det fanns inget beslutsfattande, och kraven i företagslagen för att fatta beslut uppfylldes inte som sådana.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=SE': {
    en: 'There was no quorum, and the requirements listed in the Companies Act ch.8 § 21 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til aktiebolagslagen 8 kap. 21 §.',
    sv: 'Det fanns inget beslutsfattande och de krav som anges i aktiebolagslagen kap. 8 § 21 för att fatta beslut var inte uppfyllda.',
  },
  'ThereWasNoQuorumMinutesPdf?jurisdiction=DK': {
    en: 'There was no quorum, and the requirements listed in the Companies Act § 124 for making decisions were as such not met.',
    nb: 'Styret var ikke beslutningsdyktig i henhold til selskabsloven § 124.',
    sv: 'Det fanns inget beslutsfattande och kraven i aktiebolagslagen § 124 för att fatta beslut var som sådan inte uppfyllda.',
  },
  isSubsidiaryTextFli: {
    en: 'Shall not exercise voting rights',
    nb: 'Skal ikke utøve stemmerett',
    sv: 'Ska inte utöva rösträtt',
  },
  isSubsidiaryText: {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt',
  },
  'isSubsidiaryText?jurisdiction=NO': {
    en: 'Is a subsidiary of {{orgName}} and shall not exercise voting rights, cf. the Norwegian Companies Act § 5-3 (3)',
    nb: 'Er datterselskap av {{orgName}} og skal ikke utøve stemmerett, jf. aksjeloven § 5-3 (3)',
    sv: 'Är ett dotterbolag till {{orgName}} och ska inte utöva rösträtt, jfr. den norska aktiebolagslagen § 5-3 (3)',
  },
  cantUpdateMeetingStatusTooltip: {
    en: 'Only meeting admins can change your attendance status after the meeting has started.',
    nb: 'Kun møteadministrator kan endre din deltakerstatus etter at møtet har startet.',
    sv: 'Endast mötesadministratören kan ändra din deltagarestatus efter att mötet har startat.',
  },
  VotingTieTextHelper2: {
    en: "tie; final result is based on the meeting chair's deciding vote",
    nb: 'likt stemmetall; endelig resultat er basert på møteleders avgjørende stemme',
    sv: 'slips; slutresultatet baseras på mötesordförandens avgörande röst',
  },
  'No info': {
    en: 'No info',
    nb: 'Ingen info',
    sv: 'Ingen info',
  },
  'Rendering Failure': {
    en: 'Rendering Failure',
    nb: 'Gjengivelsesfeil',
    sv: 'Rendering misslyckande',
  },
  Send: {
    en: 'Send',
    nb: 'Sende',
    sv: 'Skicka',
  },
  Reject: {
    en: 'Reject',
    nb: 'Avvis',
    sv: 'Avvisa',
  },
  Complaint: {
    en: 'Complaint',
    nb: 'Klage',
    sv: 'Klagomål',
  },
  Bounce: {
    en: 'Bounce',
    nb: 'Sprette',
    sv: 'Studsa',
  },
  InvalidUse: {
    en: 'Invalid Use',
    nb: 'Ugyldig bruksstørrelse',
  },
  UseAuthorization: {
    en: 'Use authorization',
    nb: 'Bruk fullmakt',
  },
  NoAuth: {
    en: 'No authorizations have been made yet. Press the button "New authorization" to create your first authorization. Or you can import your companies earlier authorizations by clicking on the import auths button.',
    nb: 'Ingen fullmakter er foretatt ennå. Trykk på knappen "Ny fullmakt" for å opprette din første fullmakt. Ellers kan importere bedriftens tidligere autorisasjoner ved å klikke på "Importer fra Brønnøysundregistrene".',
  },
  NoAuth2: {
    en: 'No active authorizations registered',
    nb: 'Ingen aktive fullmakter registrert',
  },

  IssueSharesAuth: {
    en: 'Issue Shares',
    nb: 'Forhøye aksjekapital',
    sv: 'T',
  },
  ConvertibleAuth: {
    en: 'Convertible loan',
    nb: 'Ta opp konvertible lån',
    sv: 'Issue Convertible debt',
  },
  BuySharesAuth: {
    en: 'Aquire own shares',
    nb: 'Erverve egne aksjer',
    sv: 'Issue Convertible debt',
  },
  ClaimSharesAuth: {
    en: 'Claim shares',
    nb: 'Erverve avtalepant i egne aksjer',
    sv: 'Issue Convertible debt',
  },
  OptionsAuth: {
    en: 'Grant options',
    nb: 'Dele ut opsjoner',
    sv: 'Issue Convertible debt',
  },
  DividendsAuth: {
    en: 'Grant dividends',
    nb: 'Utdeling av utbytte',
    sv: 'Issue Convertible debt',
  },
  IssueSharesType: {
    en: 'Issue Shares',
    nb: 'Forhøye aksjekapital',
    sv: 'T',
  },
  ConvertibleType: {
    en: 'Convertible loan',
    nb: 'Ta opp konvertible lån',
    sv: 'Issue Convertible debt',
  },
  BuySharesType: {
    en: 'Aquire own shares',
    nb: 'Erverve egne aksjer',
    sv: 'Issue Convertible debt',
  },
  ClaimSharesType: {
    en: 'Claim shares',
    nb: 'Erverve avtalepant i egne aksjer',
    sv: 'Issue Convertible debt',
  },
  OptionsType: {
    en: 'Grant options',
    nb: 'Dele ut opsjoner',
    sv: 'Issue Convertible debt',
  },
  DividendsType: {
    en: 'Grant dividends',
    nb: 'Utdeling av utbytte',
    sv: 'Issue Convertible debt',
  },
  Size: {
    en: 'Size',
    nb: 'Størrelse',
    sv: 'Størrelse sv',
  },
  Remaining: {
    en: 'Remaining',
    nb: 'Rest',
  },
  SizeRemaining: {
    en: 'Size remaining',
    nb: 'Gjenværende størrelse',
    sv: 'Størrelse sv',
  },
  SizeRemainingBeforeUse: {
    en: 'Size before use',
    nb: 'Størrelse før bruk',
    sv: 'Størrelse sv',
  },
  SizeRemainingAfterUse: {
    en: 'Remaining after use',
    nb: 'Gjenværende etter bruk',
    sv: 'Størrelse sv',
  },
  Used: {
    en: 'Used',
    nb: 'Benyttet',
  },
  UseAmountPlaceholder: {
    en: 'Use size',
    nb: 'Bruksstørrelse',
    sv: 'Størrelse sv',
  },
  UseAmountTooltip: {
    en: 'The size of the usage',
    nb: 'Størrelsen på bruken',
    sv: 'Størrelse sv',
  },
  UseDate: {
    en: 'Date Used',
    nb: 'Bruksdato',
  },
  UseDatePlaceholder: {
    en: 'Use date',
    nb: 'Bruksdato',
    sv: 'Størrelse sv',
  },
  UseDateTooltip: {
    en: 'The date of the usage',
    nb: 'Dato til bruken',
    sv: 'Størrelse sv',
  },
  UpdateUse: {
    en: 'Update use',
    nb: 'Oppdater bruk',
    sv: 'Størrelse sv',
  },
  NewUse: {
    en: 'Register use',
    nb: 'Registrer bruk',
    sv: 'UpdateUse sv',
  },
  NewUseTitle: {
    en: 'Register use of authorization',
    nb: 'Registrer bruk av fullmakt',
    sv: 'NewUseTitle sv',
  },
  DeleteUseTitle: {
    en: 'Delete use',
    nb: 'Slett bruk',
  },
  DeleteUseText: {
    en: 'Deleting a use cannot be undone. Do you want to delete?',
    nb: 'Sletting av bruk kan ikke angres. Ønsker du å slette?',
  },
  Created: {
    en: 'Created',
    nb: 'Vedtatt',
    sv: 'Størrelse sv',
  },
  Expires: {
    en: 'Expires',
    nb: 'Utløper',
    sv: 'Størrelse sv',
  },
  Registered: {
    en: 'Registered',
    nb: 'Registrert',
    sv: 'Størrelse sv',
  },
  IssuingSharesTitle: {
    en: 'Authorization For Issuing New Shares',
    nb: 'Fullmakt til å forhøye aksjekapital',
    sv: 'Størrelse sv',
  },
  ClaimTitle: {
    en: 'Authorization For Stock Claim',
    nb: 'Fullmakt til å erverve avtalepant i egne aksjer',
    sv: 'Størrelse sv',
  },
  ConvertibleTitle: {
    en: 'Authorization For Convertible Loan',
    nb: 'Fullmakt til å ta opp konvertibelt lån',
    sv: 'Størrelse sv',
  },
  BuySharesTitle: {
    en: 'Authorization For Buying Company Shares',
    nb: 'Fullmakt til å erverve egne aksjer',
    sv: 'Størrelse sv',
  },
  DividendsTitle: {
    en: 'Authorization For Granting Dividends',
    nb: 'Fullmakt til utdeling av utbytte',
    sv: 'Størrelse sv',
  },
  OptionsTitle: {
    en: 'Authorization For Granting Options',
    nb: 'Fullmakt opsjonsprogram',
    sv: 'Størrelse sv',
  },
  AuthBuySharesInfo: {
    en: "The general assembly's decision must contain information on how long the authorization will be valid, the highest nominal value of the shares the company can acquire in total, that is, the sum of the number of shares x nominal value stated in the articles of association, the minimum and maximum amount that can be paid for the shares, and in which ways the acquisition and disposal of own shares can take place. The general assembly's decision must be reported to the Norwegian companies register and must be registered before shares can be acquired in accordance with the authorization.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om hvor lenge fullmakten skal gjelde, den høyeste pålydende verdien av aksjene selskapet i alt kan erverve, det vil si summen av antall aksjer x pålydende verdi som er oppgitt i vedtektene, det minste og høyeste beløpet som kan betales for aksjene, og om hvilke måter erverv og avhendelse av egne aksjer kan skje på. Generalforsamlingens beslutning skal meldes til Foretaksregisteret og må være registrert før aksjer kan erverves i henhold til fullmakten.',
  },
  AuthClaimInfo: {
    en: "The general assembly's decision must contain information on how long the authorization is to be valid, the highest nominal value of the shares the company can acquire in total, that is, the sum of the number of shares x nominal value stated in the articles of association, the minimum and maximum amount that can be paid for the shares, and in which ways the acquisition and disposal of own shares can take. The general assembly's decision must be reported to the Norwegian companies register and must be registered before shares can be acquired in accordance with the authorization.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om hvor lenge fullmakten skal gjelde, den høyeste pålydende verdien av aksjene selskapet i alt kan erverve, det vil si summen av antall aksjer x pålydende verdi som er oppgitt i vedtektene, det minste og høyeste beløp som kan betales for aksjene, og på hvilke måter erverv og avhendelse av egne aksjer kan skje. Generalforsamlingens beslutning skal meldes til Foretaksregisteret og må være registrert før aksjer kan erverves i henhold til fullmakten.',
  },
  AuthConvertibleInfo: {
    en: "The General Assembly's decision must contain information on the total loan amount that can be taken out, the amount by which the share capital can be increased, and how long the authorization is to be valid.",
    nb: 'Generalforsamlingens beslutning skal inneholde opplysninger om det samlede lånebeløp som kan tas opp, beløpet aksjekapitalen skal kunne forhøyes med, og hvor lenge fullmakten skal gjelde.',
  },
  AuthDividendsInfo: {
    en: 'When the annual accounts for the last year have been approved, the general meeting can authorize the board to decide on the distribution of dividends. The authorization is valid until the next ordinary general meeting.',
    nb: 'Når årsregnskapet for siste år er godkjent, kan generalforsamlingen gi styret fullmakt til å beslutte utdeling av utbytte. Fullmakten gjelder frem til neste ordinære generalforsamling.',
  },
  AuthIssueSharesInfo: {
    en: "The general assembly can authorize the board to increase the share capital by issuing new shares. The general assembly's decision must state the amount by which the share capital is to be increased, and how long the authorization is to be valid. The general assembly's decision on board authorization must be reported to the Companies Register without delay. The board cannot make use of the authorization before it has been registered.",
    nb: 'Generalforsamlingen kan gi styret fullmakt til å forhøye aksjekapitalen ved nytegning av aksjer. I generalforsamlingens beslutning må det stå oppgitt beløpet aksjekapitalen skal forhøyes med, og hvor lenge fullmakten skal gjelde. Generalforsamlingens beslutning om styrefullmakt skal uten opphold meldes til Foretaksregisteret. Styret kan ikke gjøre bruk av fullmakten før den er registrert.',
  },
  AuthOptionsInfo: {
    en: 'The general assembly can authorize the board to increase the share capital by issuing new shares. In connection with such an authorization, the board at the general assembly can obtain an authorization on how much of the authorization can be used in connection with an option program.',
    nb: 'Generalforsamlingen kan gi styret fullmakt til å forhøye aksjekapitalen ved nytegning av aksjer. I forbindelse med en slik fullmakt kan styret på generalforsamling få en fullmakt på hvor mye av fullmakten som kan benyttes i forbindelse med et opsjonsprogram.',
  },
  AuthBuySharesReadMore: {
    en: 'Read more in sections 9-2 and 9-4 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven §§ 9-2 og 9-4.',
  },
  AuthClaimReadMore: {
    en: 'Read more in section 9-5 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 9-5.',
  },
  AuthConvertibleReadMore: {
    en: 'Read more in section 11-8 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 11-8.',
  },
  AuthDividendsReadMore: {
    en: 'Read more in section 8-2 of the Norwegian Companies Act.',
    nb: 'Les mer i aksjeloven § 8-2.',
  },
  AuthIssueSharesReadMore: {
    en: 'Read more in chapter 10 of the Norwegian Company Act.',
    nb: 'Les mer i aksjeloven kapittel 10.',
  },
  AuthBuySharesReadMoreASA: {
    en: 'Read more in section 9-2 and 9-4 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven §§ 9-2 og 9-4.',
  },
  AuthClaimReadMoreASA: {
    en: 'Read more in section 9-5 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 9-5.',
  },
  AuthConvertibleReadMoreASA: {
    en: 'Read more in section 11-8 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 11-8.',
  },
  AuthDividendsReadMoreASA: {
    en: 'Read more in section 8-2 of the Norwegian public Limited Liability Companies Act.',
    nb: 'Les mer i allmennaksjeloven § 8-2.',
  },
  AuthIssueSharesReadMoreASA: {
    en: 'Read more in the Norwegian public Limited Liability Companies Act, chapter 10.',
    nb: 'Les mer i allmennaksjeloven kapittel 10.',
  },

  ImportAuthorizations: {
    en: 'Import from Brønnøysund registers',
    nb: 'Importer fra Brønnøysundregistrene',
    sv: 'Størrelse sv',
  },
  IssueSharesAuthCreated: {
    en: 'Issue shares authorization created',
    nb: 'Fullmakt til å øke aksjekapitalen ble registrert',
    sv: 'Størrelse sv',
  },
  ConvertibleLoanAuthCreated: {
    en: 'Convertible loan authorization created',
    nb: 'Fullmakt til å ta opp konvertibelt lån ble registrert',
    sv: 'Størrelse sv',
  },
  BuySharesAuthCreated: {
    en: 'Buy shares authorization created',
    nb: 'Fullmakt til å erverve egne aksjer ble registrert',
    sv: 'Størrelse sv',
  },
  ClaimAuthCreated: {
    en: 'Claim authorization created',
    nb: 'Fullmakt til å erverve avtalepant på egne aksjer ble registrert',
    sv: 'Størrelse sv',
  },
  DividendsAuthCreated: {
    en: 'Dividends authorization created',
    nb: 'Utbytte fullmakt ble registrert',
    sv: 'Størrelse sv',
  },
  OptionsAuthCreated: {
    en: 'Options authorization created',
    nb: 'Opsjons fullmakt ble registrert',
    sv: 'Størrelse sv',
  },
  AuthDeleted: {
    en: 'Authorization deleted',
    nb: 'Fullmakt slettet',
    sv: 'Størrelse sv',
  },
  AuthSizePlaceholder: {
    en: 'Authorization Size (NOK)',
    nb: 'Fullmaktens størrelse (NOK)',
    sv: 'Størrelse sv',
  },
  AuthSizeTooltip: {
    en: 'The size of the authorization (NOK)',
    nb: 'Størrelsen på fullmakten (NOK)',
    sv: 'Størrelse sv',
  },
  LoanSizePlaceholder: {
    en: 'Loan size (NOK)',
    nb: 'Lånets størrelse (NOK)',
    sv: 'Størrelse sv',
  },
  LoanSizeTooltip: {
    en: 'The size of the loan (NOK)',
    nb: 'Størrelsen på lånet (NOK)',
    sv: 'Størrelse sv',
  },
  AuthStartDate: {
    en: 'Date given',
    nb: 'Dato gitt',
  },
  AuthStartDateTooltip: {
    en: 'The starting date of the authorization',
    nb: 'Datoen fullmakten er gitt',
  },
  AuthExpirationDate: {
    en: 'Date expires',
    nb: 'Utløpsdato',
  },
  AuthExpirationDateTooltip: {
    en: 'The expiration date of the authorization',
    nb: 'Utløpsdatoen til fullmakten',
  },
  RegistrationDate: {
    en: 'Registered in Brreg',
    nb: 'Registrert i Brreg',
    sv: 'Størrelse sv',
  },
  RegistrationDateTooltip: {
    en: 'The Registration date in the Foretaksregister',
    nb: 'Datoen fullmakten ble registrert i Foretaksregistert',
    sv: 'Størrelse sv',
  },
  Notes: {
    en: 'Notes',
    nb: 'Notater',
    sv: 'Størrelse sv',
  },
  NotesTooltip: {
    en: 'Here you can enter free text, for example whether there are criteria that must be met for the agreement to be exercised.',
    nb: 'Here you can enter free text, for example whether there are criteria that must be met for the agreement to be exercised.',
    sv: 'Størrelse sv',
  },
  NotesPlaceholder: {
    en: 'Here you can enter notes for the authorization',
    nb: 'Her kan du skrive inn notater for fullmakten',
    sv: 'Størrelse sv',
  },

  Delivery: {
    en: 'Delivery',
    nb: 'Leveranse',
    sv: 'Leverans',
  },
  Open: {
    en: 'Open',
    nb: 'Åpen',
    sv: 'Öppna',
  },
  Click: {
    en: 'Click',
    nb: 'Klikk',
    sv: 'Klick',
  },
  Library: {
    en: 'Library',
    nb: 'Bibliotek',
    sv: 'Bibliotek',
  },
  'New item': {
    en: 'New item',
    nb: 'Ny sak',
    sv: 'Nytt ämne',
  },
  'Add new agenda item': {
    en: 'Add new agenda item',
    nb: 'Legg til nytt agenda punkt',
    sv: 'Lägg till nytt ämne',
  },
  DeleteEmissionPlanQuestion: {
    en: 'You are about to delete a capital increase. This cannot be undone. Do you want to delete?',
    nb: 'Du er i ferd med å slette en kapitalforhøyelse. Dette kan ikke angres. Ønsker du å slette? ',
    sv: 'Du håller på att ta bort en kapitalökning. Detta kan inte ångras. Vill du ta bort?',
  },
  OrgbrainSignFooter: {
    en: 'This document is signed with Orgbrain eSign\n- a standard electronic signature compliant\nwith EU regulation 910/2014 (eIDAS).',
    nb: 'Dokumentet er signert med Orgbrain eSign\n- en standard elektronisk signatur i samsvar\nmed EU-forordning 910/2014 (eIDAS).',
  },

  OrgbrainSignQRFooter: {
    en: 'For more information see\n \nOr scan the QR code',
    nb: 'For mer informasjon se\n \nEller skann QR koden',
  },

  OrgbrainSignHeader: {
    en: 'This document is signed with Orgbrain eSign - a standard electronic\nsignature compliant with EU regulation 910/2014 (eIDAS).',
    nb: 'Dokumentet er signert med Orgbrain eSign - en standard elektronisk\nsignatur i samsvar med EU-forordning 910/2014 (eIDAS).',
  },

  SignicatRequestHeader: {
    en: 'Orgbrain Signing Request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskTitle: {
    en: 'Orgbrain signing request',
    nb: 'Orgbrain signering forespørsel.',
  },

  SignicatTaskMessage: {
    en: 'Please sign this document',
    nb: 'Vennligst signer dette dokumentet',
  },

  FormalitiesTopic: {
    en: 'Formalities',
    nb: 'Formalia',
  },
  ReportingTopic: {
    en: 'Reporting',
    nb: 'Rapportering',
  },
  EconomyTopic: {
    en: 'Economy',
    nb: 'Økonomi',
  },
  PersonnelTopic: {
    en: 'Personnel',
    nb: 'Personale',
  },
  AppointmentsTopic: {
    en: 'Appointments',
    nb: 'Avtaler',
  },
  SupervisionTopic: {
    en: 'Supervision',
    nb: 'Tilsyn',
  },
  ShareholdersTopic: {
    en: 'Owners/Members',
    nb: 'Eiere/Medlemmer',
  },
  CEOTopic: {
    en: 'CEO',
    nb: 'Daglig leder',
  },
  StrategyTopic: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ProxyTopic: {
    en: 'Power of attorney',
    nb: 'Fullmakter',
  },
  StatutesTopic: {
    en: 'Statutes',
    nb: 'Vedtekter',
  },
  NewMessageFrom: {
    en: 'New message from',
    nb: 'Ny melding fra',
  },
  ClickHereToViewTheMessage: {
    en: 'Click here to view the message',
    nb: 'Klikk her for å se meldingen',
  },
  'Email address update': {
    en: 'Email address update',
    nb: 'Oppdatering av e-postadresse',
  },
  'Please use the code below to confirm your new email address': {
    en: 'Please use the code below to confirm your new email address',
    nb: 'Vennligst bekreft din nye e-postadresse ved å benytte koden under',
  },
  resetPassword: {
    en: 'Reset password',
    nb: 'Tilbakestille passord',
  },
  weReceivedRequest: {
    en: 'We have received a request to reset the password for',
    nb: 'Vi har mottatt en forespørsel om å tilbakestille passordet for',
  },
  pleaseUseLink: {
    en: 'Please use the following link',
    nb: 'Bruk følgende lenke',
  },
  HelloEmailText: {
    en: 'Hi',
    nb: 'Hei',
  },
  InvitedToSurveyText: {
    en: 'You have been invited to respond to a survey for {{ORG}}. The survey is anonymous.',
    nb: 'Du er blitt invitert til å svare på en undersøkelse for {{ORG}}. Undersøkelsen er anonym.',
  },
  ReminderForSigningRequest: {
    en: 'Reminder for signing request',
    nb: 'Påminnelse om signeringsforespørsel',
  },

  GaveActionPointResponsibility: {
    en: 'gave you a new task or question titled',
    nb: 'har tildelt deg, eller lagt til informasjon, i oppgaven',
  },
  Task: {
    en: 'Task',
    nb: 'Oppgave',
  },
  WithDeadline: {
    en: 'With deadline:',
    nb: 'Frist:',
  },
  ClickBelowTask: {
    en: 'Click the button below to view the task.',
    nb: 'Klikk på knappen under for å se oppgaven.',
  },
  HasSavedInformationTask: {
    en: 'has entered new information in the task',
    nb: 'har lagt inn ny informasjon i oppgaven',
  },
  GoToTask: {
    en: 'Go to Task',
    nb: 'Gå til oppgave',
  },

  SharesHaveBeenTransferredToYou: {
    en: 'Shares have been transferred to you',
    nb: 'Aksjer har blitt overført til deg',
  },
  MessageAboutTransferOfSharesIn: {
    nb: 'Melding om overføring av aksjer i',
    en: 'Message about transfer of shares in',
  },

  TheFollowingHasBeenRegisteredOn: {
    en: 'The following has been registered on ',
    nb: 'Følgende er blitt registrert på ',
  },
  InTheShareholderRegisterOf: {
    en: ' in the shareholder register of ',
    nb: ' i aksjeeierboken til ',
  },
  Recipient: {
    en: 'Recipient',
    nb: 'Mottaker',
  },
  BestRegards: {
    en: 'Best regards',
    nb: 'Vennlig hilsen',
  },
  Sender: {
    en: 'Sender',
    nb: 'Avsender',
  },
  Hi: {
    en: 'Hi',
    nb: 'Hei',
  },
  Time: {
    en: 'Time',
    nb: 'Tidspunkt',
  },
  NewInventory: {
    en: 'New inventory',
    nb: 'Ny beholdning',
  },
  ShareClass: {
    en: 'Share class',
    nb: 'Aksjeklasse',
  },
  Amount: {
    en: 'Amount',
    nb: 'Antall',
  },

  SharesWithClass: {
    en: 'shares, with class',
    nb: 'aksjer, med aksjeklasse',
  },
  HasBeenRegisteredToYou: {
    en: 'has been registered on you in an emission',
    nb: 'har blitt registrert på deg i en emisjon',
  },
  ShareWithClass: {
    en: 'share, with class',
    nb: 'aksje, med aksjeklasse',
  },
  OfYourSharesWithClass: {
    en: 'of your shares, with class',
    nb: 'av dine aksjer, med aksjeklasse',
  },
  in: {
    en: 'in',
    nb: 'i',
  },
  HasBeenTransferredToYouFrom: {
    en: 'has been transferred to you from',
    nb: 'har blitt overført til deg fra',
  },
  HasBeenTransferredTo: {
    en: 'has been transferred to',
    nb: 'har blitt overført til',
  },
  WeWantToInformYouThat: {
    en: 'We would like to inform you that',
    nb: 'Vi vil med dette opplyse deg at',
  },
  HasBeenRegisteredAsBeneficialOwner: {
    en: 'has been registered as a beneficial owner with',
    nb: 'er registrert som reell rettighetshaver hos',
  },
  HasDecided: {
    nb: 'har vedtatt å tildele',
    en: 'has decided to award',
  },
  AsFollows: {
    nb: 'som følger',
    en: 'as follows',
  },
  PayoutDate: {
    en: 'Payout date',
    nb: 'Utbetalingsdato',
  },
  PayoutPerShare: {
    en: 'Payout per share',
    nb: 'Utbetaling per aksje',
  },
  TotalPayout: {
    en: 'Total payout',
    nb: 'Total utbetaling',
  },
  TotalPaidOut: {
    en: 'Paid out total',
    nb: 'Utbetaling totalt',
  },
  PayoutAccount: {
    en: 'Payout account',
    nb: 'Utbetalingskonto',
  },
  HeadlineTransaction1: {
    en: 'Decision on award of',
    nb: 'Vedtak om tildeling av',
  },
  HeadlineTransaction2: {
    en: 'from',
    nb: 'fra',
  },
  Shareholderreciver: {
    en: 'Shareholder',
    nb: 'Aksjonær',
  },
  Shareownerreciver: {
    en: 'Shareowner',
    nb: 'Aksjeeier',
  },
  NumberOfNewShares: {
    en: 'New shares',
    nb: 'Nye aksjer',
  },
  NewShareholding: {
    en: 'New shareholding',
    nb: 'Ny beholdning',
  },
  AwardTime: {
    en: 'Award time',
    nb: 'Tidspunkt',
  },
  Regards: {
    en: 'Regards',
    nb: 'Hilsen',
  },

  NumberOfShares: {
    en: 'Your current shareholding',
    nb: 'Aksjebeholdning',
  },
  BankNrNotSet: {
    nb: 'OBS! Det ser ut som vi ikke har registrert hvilken bankkonto utbetalingen skal skje til. Vennligst ta kontakt med oss slik at vi kan få registrert dette.',
    en: 'OBS! It seems like we have not registered which bank account the payout should be made to. Please contact us so that we can register this.',
  },
  ByBeneficialOwnerMeaning: {
    en: 'The law defines a beneficial owner as a natural person or persons who ultimately owns or controls a legal person, event, entity or other association.',
    nb: 'Med reell rettighetshaver menes i loven den eller de fysiske personene som i siste instans eier eller kontrollerer en juridisk person, arrangement, enhet eller annen sammenslutning.',
  },
  YouHaveBeenRegisteredAsABeneficialOwner: {
    en: 'You have been registered as a beneficial owner',
    nb: 'Du har blitt registrert som reell rettighetshaver',
  },
  ReadMoreHere: {
    en: 'Read more',
    nb: 'Les mer',
  },
  InvitationToVoteForMeetingTime: {
    en: 'Invitation to find a meeting time',
    nb: 'Invitasjon til å finne møtetidspunkt',
  },
  Svarfrist: {
    en: 'Reply before',
    nb: 'Svarfrist',
  },
  VoteTimeButtonText: {
    en: 'Vote on time',
    nb: 'Stem på tidspunkt',
  },
  BodyTextEmailForMeetingTime: {
    en: 'You have been invited to provide input on a meeting time for a scheduled meeting {{meetingName}} in {{orgName}}. Press the button below to give your feedback.',
    nb: 'Du har blitt invitert til å gi innspill til møtetidspunkt for et planlagt møte {{meetingName}} i {{orgName}}. Trykk på knappen under for å gi din tilbakemelding.',
  },
  TransactionType: {
    en: 'Transaction',
    nb: 'Transaksjon',
  },
  TransferenceTo: {
    en: 'Transference to',
    nb: 'Overføring til',
  },
  TransferenceFrom: {
    en: 'Transference from',
    nb: 'Overføring fra',
  },
  TODO: {
    en: 'Todo',
    nb: 'Skal gjøres',
  },
  DOING: {
    en: 'Doing',
    nb: 'Pågår',
  },
  DONE: {
    en: 'Done',
    nb: 'Ferdig',
  },
  CRITICAL: {
    en: 'Critical',
    nb: 'Kritisk',
  },
  HIGH: {
    en: 'High',
    nb: 'Høy',
  },
  MEDIUM: {
    en: 'Medium',
    nb: 'Middels',
  },
  LOW: {
    en: 'Low',
    nb: 'Lav',
  },
  MAYBE: {
    en: 'No priority',
    nb: 'Ingen',
  },
  Instruction: {
    en: 'Instruction',
    nb: 'Instruksjon',
  },
  AttachmentTo: {
    en: 'Attachment to:',
    nb: 'Vedlegg til:',
  },
  MeetingBook: {
    en: 'Meeting book',
    nb: 'Møtebok',
  },
  sharedFolderTooltip: {
    en: 'Shared folder is a function where you as an administrator can create a folder that can be shared with other organizations that are in the same group structure or other organizations where you are an administrator. It is the organization that creates the folder that owns the folder, and that covers any costs of storage and signing.',
    nb: 'Delt mappe er en funksjon der du som administrator kan opprette en mappe som kan deles med andre organisasjoner som er i samme konsernstruktur eller øvrige organisasjoner der du er administrator. Det er organisasjonen som oppretter mappen som er eier av mappen, og som dekker eventuelle kostnader ved lagring og signering.',
  },
  Tasks: {
    nb: 'Oppgaver',
    en: 'Tasks',
  },
  tasks: {
    nb: 'oppgaver',
    en: 'tasks',
  },
  TasksSelectDocumentType: {
    en: 'Select type of the document',
    nb: 'Velg type dokument',
  },
  AnErrorOccured: {
    en: 'An error occured',
    nb: 'Det oppstod en feil',
  },
  NewTask: {
    en: 'New task',
    nb: 'Ny oppgave',
  },
  DownloadTasks: {
    en: 'Download tasks',
    nb: 'Last ned oppgaver',
  },
  TasksModuleHelper: {
    nb: 'Her kan du lage oppgaver med tidsfrist og prioritet som du kan delegere til brukere i portalen. Du kan også opprette private oppgaver («huskeliste») som kun du har tilgang til. Det er også mulig å opprette en dialog med de som har tilgang til en oppgave.',
    en: 'Here you can create tasks with deadlines and priorities that you can delegate to users in the portal. You can also create private tasks ("to-do list") that only you have access to. It is also possible to create a dialogue with those who have access to a task.',
  },
  IncompleteTasks: {
    en: 'Incomplete tasks',
    nb: 'Uferdige oppgaver',
  },
  CompletedTasks: {
    en: 'Completed tasks',
    nb: 'Fullførte oppgaver',
  },
  MyIncompleteTasks: {
    en: 'My incomplete tasks',
    nb: 'Mine uferdige oppgaver',
  },
  MyCompletedTasks: {
    en: 'My completed tasks',
    nb: 'Mine fullførte oppgaver',
  },
  AllTasks: {
    en: 'All',
    nb: 'Alle',
  },
  SearchTasks: {
    en: 'Search',
    nb: 'Søk',
  },
  ListViewTasks: {
    en: 'List view',
    nb: 'Listevisning',
  },
  DiagramViewTasks: {
    en: 'Chart view',
    nb: 'Diagramvisning',
  },
  StatusTasks: {
    en: 'Status',
    nb: 'Status',
  },
  PriorityTasks: {
    en: 'Priority',
    nb: 'Prioritet',
  },
  TitleTasks: {
    en: 'Title',
    nb: 'Tittel',
  },
  ResponsibleTasks: {
    en: 'Responsible',
    nb: 'Ansvarlig',
  },
  DeadlineTasks: {
    en: 'Deadline',
    nb: 'Frist',
  },
  EditTask: {
    en: 'Edit',
    nb: 'Rediger',
  },
  Delete: {
    en: 'Delete',
    nb: 'Slett',
  },
  'Org.nr': {
    en: 'Org.nr',
    nb: 'Org.nr',
  },
  EditUser: {
    en: 'Edit user',
    nb: 'Rediger bruker',
  },
  YouHaveNotBeenInvitedToAnySurvey: {
    en: 'You have not been invited to any survey',
    nb: 'Du er ikke invitert til noen spørreundersøkelse',
  },
  TransfereUser: {
    en: 'Transfere user',
    nb: 'Overfør bruker',
  },
  DeleteContent: {
    en: 'Delete content',
    nb: 'Slett innhold',
  },
  PleaseConfirmPermanentDelete: {
    en: 'You are about to delete chosen content permanently. this action cannot be undone. Do you wish to delete permanently?',
    nb: 'Du er i ferd med å slette valgte innhold permanent. Dette kan ikke angres. Ønsker du å slette permanent?',
  },

  Menu: {
    en: 'Menu',
    nb: 'Meny',
  },
  rolesText: {
    en: 'Roles',
    nb: 'Roller',
  },
  OrgOrgbrainWatermarkinginfo: {
    en: 'It is possible for the administrator to set the settings so that a "watermark" is added to documents downloaded from the portal. This makes it easier to track documents that have been downloaded.',
    nb: 'Det er mulig for administrator å sette innstillingene slik at det blir lagt inn et «vannmerke» («watermark») på dokumenter som lastes ned fra portalen. Dette gjør at dokumenter på avveie lettere kan spores til den som har utført nedlastingen.',
  },
  OrgEditUserInfoBox: {
    en: 'Here the administrator can assign and remove access to the portal. The role(s) the users are assigned determines what they get access to. When inviting new users, make sure that the field "Send invitation by email" is filled out so that the new user receives the message. If you forget this, just check "Send invitation by email" and then save, then the recipient will receive an email. In the email that is sent, the person will be asked to register their personal user account.',
    nb: 'Her kan administrator tildele og fjerne tilganger til portalen. Rollen(e) brukerne blir tildelt bestemmer hva de får tilgang til. Ved invitasjon av nye brukere, pass på å at feltet “Send invitasjon på e-post” er fylt ut slik at den nye brukeren mottar meldingen. Dersom du glemmer dette, er det bare å krysse av “Send invitasjon på e-post” og så lagre, da vil mottakeren motta e-post. I e-posten som sendes vil vedkommende bli bedt om å registrere sin personlige brukerkonto.',
  },
  ResendSendEmail: {
    en: 'Resend email',
    nb: 'Send epost på nytt',
  },
  userIdentityRequirementsHelp1: {
    en: 'To have good control over users who are invited into the portal, manual control should be carried out before a new user is added. The portal requires a minimum that the user must have control over the email address they are invited with.',
    nb: 'For å ha god kontroll på brukere som inviteres inn i portalen, bør det gjennomføres manuell kontroll før ny bruker leggs til. Portalen krever minimum at brukeren må ha kontroll på e-postadressen som de inviteres inn med.',
  },
  userIdentityRequirementsHelp2: {
    en: 'If the organization wants increased control, a requirement can be added that all users must confirm their identity further with one of the methods specified here, otherwise the user will be denied access to the organization. It is possible to choose both methods, but the user only needs to confirm their identity once. It is possible for an administrator to override an identification by verifying the user manually.',
    nb: 'Dersom organisasjonen ønsker økt kontroll, kan det legges inn et krav om at alle brukerne må bekrefte sin identitet ytterligere med en av metodene som er angitt her, ellers blir brukeren nektet tilgang til organisasjonen. Det er mulig å velge begge metodene, men brukeren trenger kun å bekrefte sin identitet én gang. Det er mulig for en administrator å overstyre en identifisering ved å verifisere brukeren manuelt.',
  },
  userIdentityRequirementsHelp3: {
    en: 'NB: The organization is billed NOK 5 per user who confirms their identity. See the terms of use for further information.',
    nb: 'NB: Organisasjonen faktureres kr 5 pr. bruker som bekrefter identiteten. Se brukervilkårene for ytterligere opplysninger.',
  },
  ImportOrganizationInfo: {
    en: 'Import from Brønnøysund',
    nb: 'Import fra Brønnøysund',
  },
  AskForAssistance: {
    en: 'Get expert help',
    nb: 'Få eksperthjelp',
  },
  //I would like to be contacted at phone
  IWouldLikeToBeContactedAtPhone: {
    en: 'I would like to be contacted at phone',
    nb: 'Jeg ønsker å bli kontaktet på telefon',
  },

  askForAssistanceButton: {
    en: 'Get expert help',
    nb: 'Be om bistand',
  },
  CanAlsoContactOnEmail: {
    en: 'You can also reach us by email: support@orgbrain.no',
    nb: 'Du kan også nå oss på e-post: support@orgbrain.no',
  },
  SupportRequestFrom: {
    en: 'Support request from',
    nb: 'Forespørsel fra',
  },
  HasRequestedAssistanceOnPhone: {
    // has requested assistance. Contact the the user on
    en: 'has requested assistance. Contact the the user on phone',
    nb: 'har bedt om konsulentbistand. Kontakt brukeren på telefon',
  },
  GetAssistanceText1: {
    en: 'Do you have busy days? Get help with performing your tasks in the portal by ',
    nb: 'Har du travle dager? Få hjelp med utføre dine oppgaver i portalen av en ',
  },
  GetAssistanceText2: {
    en: 'a consultant who is an expert on the software. The price is NOK 400 for each',
    nb: 'konsulent som er ekspert på programvaren. Prisen er kr 400 for hvert',
  },
  GetAssistanceText3: {
    en: 'quarter started.',
    nb: 'påbegynt kvarter.',
  },
  GetAssistanceTooltip: {
    en: 'Do you have busy days? Get help with performing your tasks in the portal by a consultant who is an expert on the software.',
    nb: 'Har du travle dager? Få hjelp med utføre dine oppgaver i portalen av en konsulent som er ekspert på programvaren.',
  },
  Parvalue: {
    en: 'Par value',
    nb: 'Pålydende',
  },
  NewUser: {
    en: 'New user',
    nb: 'Ny bruker',
  },
  Rights: {
    en: 'Rights',
    nb: 'Rettigheter',
  },
  Access: {
    en: 'Access',
    nb: 'Tilgang',
  },

  ChangesToPremissionsDiffersFromParent: {
    en: 'Changes have been made. There are now <b>deviations</b> in access from the folder above',
    nb: 'Det er foretatt endringer. Det er nå <b>avvik</b> i tilgang fra mappen over',
  },
  AccessControlWarningContainer: {
    en: 'Users marked in red have been given access to this level without having access to the level above. You must also grant access to the folders above for the access to take effect.',
    nb: 'Brukere som er merket rødt, er blitt gitt tilgang til dette nivået uten å ha fått tilgang til nivået over. Du må også gi tilgang på mappene over for at tilgangen skal ha effekt.',
  },
  CreateQuestionnaire: {
    en: 'Create questionnaire',
    nb: 'Opprett spørreskjema',
  },

  dueDiligenceInfoBox: {
    en: 'Here you have the opportunity to limit which users should have access to the content of folders and files. Access you set here will be reused in all subfolders. You also have the opportunity to limit access further to fewer users in subfolders.',
    nb: 'Her har du mulighet til å avgrense hvilke brukere som skal ha tilgang til innholdet i mapper og filer. Tilganger du setter her vil gjenbrukes i alle underliggende mapper. Du har også mulighet til å avgrense tilgangen ytterligere til færre brukere i underliggende mapper.',
  },
  CopyForm: {
    en: 'Copy form',
    nb: 'Kopier skjema',
  },
  dobfield: {
    en: 'Date of Birth (6 digits)',
    nb: 'Fødselsdato (6 siffer)',
  },
  PNRLable: {
    nb: 'P. nr. (5 siffer) / F.nr (11 siffer)',
    en: 'SSN (5 digits) / B.nr (11 digits)',
  },

  helpTextAnnualPlan: {
    en: 'By choosing "Based on previous meetings", a proposal for an annual plan will be made based on the board meetings that have been held in the last 12 months. The meetings will be held on the same day of the week as the year before, adjusted for any official holidays. By choosing "Completely new annual plan", you will get the opportunity to set up a completely new annual plan based on your adjustments.',
    nb: 'Ved valg av «Basert på tidligere møter» vil det bli laget forslag til årsplan basert på styremøtene som har vært de siste 12 måneder. Møtene blir lagt på samme ukedag som året før, justert for eventuelle offisielle fridager. Ved valg av «Helt ny årsplan» vil du få muligheten til å sette opp helt ny årsplan basert på dine tilpasninger.',
  },
  AddSubTask: {
    en: 'Add subtask',
    nb: 'Legg til underoppgave',
  },
  BusinessAdress: {
    nb: 'Forretningsadresse',
    en: 'Business address',
  },
  NewClaim: {
    en: 'New claim',
    nb: 'Ny pant',
  },

  DateEntered: {
    en: 'Date entered',
    nb: 'Dato innført',
  },
  PostalAddress: {
    nb: 'Postadresse',
    en: 'Postal address',
  },
  AdditionalInformation: {
    en: 'Additional information',
    nb: 'Ytterligere opplysninger',
  },
  DescriptionTask: {
    en: 'Description',
    nb: 'Beskrivelse',
  },
  YouHaveNoSubgroups: {
    en: 'You have no subgroups',
    nb: 'Du har ingen undergrupper',
  },
  PleaseAddSubgroupNameAndAdminDetails: {
    en: 'Please add subgroup name and admin details',
    nb: 'Legg til undergruppens navn og admin detaljer',
  },
  Meeting: {
    en: 'Meeting',
    nb: 'Møte',
  },
  AssociatedFile: {
    en: 'Associated file',
    nb: 'Tilknyttet fil',
  },
  HidePath: {
    en: 'Hide path',
    nb: 'Skjul filsti',
  },
  ShowPath: {
    en: 'Show path',
    nb: 'Vis filsti',
  },
  SubTasks: {
    en: 'Subtasks',
    nb: 'Underoppgaver',
  },
  ParentTask: {
    en: 'Parent task',
    nb: 'Foreldreoppgave',
  },
  DependsOn: {
    en: 'Depends on',
    nb: 'Avhengig av',
  },
  Comments: {
    en: 'Comments',
    nb: 'Kommentarer',
  },
  StartTask: {
    en: 'Start',
    nb: 'Start',
  },
  ModifiedTasks: {
    en: 'Modified',
    nb: 'Endret',
  },
  ProgressTask: {
    en: 'Progress',
    nb: 'Fremdrift',
  },
  ShowFollowupInCalendar: {
    en: 'The user who is set as responsible for the task will see the task in the calendar.',
    nb: 'Brukeren som er satt som ansvarlig for oppgaven vil se oppgaven i kalenderen.',
  },
  ShowInCalendar: {
    en: 'Show in calendar',
    nb: 'Vis i kalender',
  },
  SendNotificationEmail: {
    en: 'Send notification by email',
    nb: 'Send varsling på e-post',
  },
  SendToResponsibleTooltip: {
    en: 'The user who is set as responsible for the task will receive a notification by e-mail every time a change is saved or a comment is added.',
    nb: 'Brukeren som er satt som ansvarlig for oppgaven vil motta en varsling på e-post hver gang en endring lagres eller en kommentar legges til.',
  },

  SendToResponsible: {
    en: 'Send to responsible',
    nb: 'Send til ansvarlig',
  },
  SendToMorePeople: {
    en: 'Send to more people',
    nb: 'Send til flere',
  },
  IncludeTheDescriptionTooltip: {
    nb: 'Hvis du ikke huker av her, vil kun tittelen på oppgaven sendes i e-posten, slik at mottakerne må følge lenken til Orgbrain for å se beskrivelsen av oppgaven.',
    en: 'If you do not check here, only the title of the task will be sent in the email, so that the recipients must follow the link to Orgbrain to see the description of the task.',
  },
  IncludeDescriptionInEmail: {
    en: 'Include the description in the email',
    nb: 'Inkluder beskrivelsen i e-posten',
  },
  Cancel: {
    en: 'Cancel',
    nb: 'Avbryt',
  },
  Save: {
    en: 'Save',
    nb: 'Lagre',
  },
  TaskPriority: {
    en: 'Task priority',
    nb: 'Oppgaveprioritet',
  },
  TaskStatus: {
    en: 'Task status',
    nb: 'Oppgavestatus',
  },
  TaskProgress: {
    en: 'Task progress',
    nb: 'Oppgavefremdrift',
  },
  AuthToBoard: {
    en: 'Board Authorizations',
    nb: 'Styrefullmakter',
  },
  Title: {
    en: 'Title',
    nb: 'Tittel',
  },
  TitleTooltip: {
    en: 'Give the authorization a title',
    nb: 'Her gir du fullmakten en tittel',
  },
  ownersIdentity: {
    en: 'Owners identity',
    nb: 'Eiers identitet',
  },
  ThisIsAPrivateTask: {
    en: 'This is a private task',
    nb: 'Dette er en privat oppgave',
  },
  StartTime: {
    en: 'Start time',
    nb: 'Starttid',
  },
  DeleteTask: {
    en: 'Delete task',
    nb: 'Slett oppgave',
  },
  'Deleting a task cannot be undone. Do you want to delete this task permanently?': {
    en: 'Deleting a task cannot be undone. Do you want to delete this task permanently?',
    nb: 'Sletting av en oppgave kan ikke angres. Ønsker du å slette denne oppgaven permanent?',
  },
  Undo: {
    en: 'Undo',
    nb: 'Angre',
  },
  TaskCompleted: {
    en: 'Task completed',
    nb: 'Oppgave fullført',
  },
  UnlinkFile: {
    en: 'Do you want to disconnect this task from a data room file?',
    nb: 'Vil fjerne koblingen mellom denne oppgaven og dataromfilen?',
  },
  UnlinkFileWarningText: {
    en: 'The file in attachments will not be deleted automatically.',
    nb: 'Filen i vedlegg vil ikke bli slettet automatisk.',
  },
  Ok: {
    en: 'Ok',
    nb: 'Ok',
  },
  DisabledTaskTooltip: {
    en: 'You do not have permission to modify this task',
    nb: 'Du har ikke tillatelse til å endre denne oppgaven',
  },
  Critical: {
    en: 'Critical',
    nb: 'Kritisk',
  },
  High: {
    en: 'High',
    nb: 'Høy',
  },
  Medium: {
    en: 'Medium',
    nb: 'Middels',
  },
  Low: {
    en: 'Low',
    nb: 'Lav',
  },
  Maybe: {
    en: 'No priority',
    nb: 'Ingen prioritet',
  },
  None: {
    en: 'No priority',
    nb: 'Ingen prioritet',
  },
  Ongoing: {
    en: 'Ongoing',
    nb: 'Pågår',
  },
  NotDone: {
    en: 'Not done',
    nb: 'Ikke gjort',
  },
  Done: {
    en: 'Done',
    nb: 'Ferdig',
  },
  CreateATask: {
    en: 'Create a task',
    nb: 'Opprett en oppgave',
  },
  Create: {
    en: 'Create',
    nb: 'Opprett',
  },
  CreatedBy: {
    en: 'Created by',
    nb: 'Opprettet av',
  },
  PrivateTaskTooltip: {
    nb: 'Dersom du markerer en oppgave som privat, vil oppgaven kun være synlig for deg.',
    en: 'If you mark a task as private, the task will only be visible to you.',
  },
  Warning: {
    en: 'Warning',
    nb: 'Advarsel',
  },

  CloseTaskEditWarning: {
    nb: 'Du har lagt inn ny informasjon som ikke er lagret. Ønsker du å fortsette uten å lagre eller gå tilbake for å lagre?',
    en: 'You have entered new information that has not been saved. Do you want to continue without saving or go back to save?',
  },
  Continue: {
    nb: 'Fortsett',
    en: 'Continue',
  },
  GoBack: {
    nb: 'Gå tilbake',
    en: 'Go back',
  },
  TaskNotChanged: {
    nb: 'Oppgaven er ikke endret',
    en: 'The task is not changed',
  },
  AddSubTaskTooltip: {
    nb: 'Her kan du opprette en ny oppgave som er del av denne oppgaven',
    en: 'Here you can create a new task that is part of this task',
  },
  NoUndoneTasks: {
    nb: 'Ingen uferdige oppgaver',
    en: 'No undone tasks',
  },
  ConfirmSendingEmails: {
    en: 'Please confirm sending emails',
    nb: 'Vennligst bekreft sending av e-post',
  },
  EmailWillContainTextWarning: {
    en: 'An email will be sent. The e-mail will contain the text you have entered. Be careful about adding sensitive information to the message',
    nb: 'En e-post vil bli sendt. E-posten vil inneholde teksten du har skrevet inn. Vær forsiktig med å legge til sensitiv informasjon i meldingen',
  },
  EmailsWillBeSent: {
    en: 'Emails will be sent',
    nb: 'E-poster vil bli sendt',
  },
  instructionsTobeGivenPowerOfAthorizedInfo: {
    en: 'Here you can choose to enter instructions on how you want the recipient of the power of attorney to vote on one or more of the cases that are under consideration.',
    nb: 'Her kan du velge å legge inn en instruksjon på hvordan du ønsker at mottakeren av fullmakten skal stemme på en eller flere av de sakene som er til behandling.',
  },
  signingPowerOfAthorizedInfo: {
    en: 'If an administrator registers a power of attorney on behalf of others, the administrator signs to confirm that the power of attorney has been received.',
    nb: 'Dersom en administrator registrerer en fullmakt på vegne av andre, signerer administrator for å bekrefte at fullmakt er mottatt.',
  },
  SendNotification: {
    en: 'Send notification',
    nb: 'Send varsel',
  },
  signedBy: {
    en: 'Signed by',
    nb: 'Signert av',
  },
  LongTextInTheBottomProxy: {
    en: 'If the person who has received power of attorney don`t have access to participate digitally using Orgbrain`s portal, the person must either attend by personal appearance or otherwise stated by the company.',
    nb: 'Dersom den som er blitt tildelt fullmakt ikke er gitt tilgang til å delta digitalt via portalen, må vedkommende som er gitt fullmakt delta ved personlig oppmøte eller på annen måte oppgitt av selskapet.',
  },
  AreYouSure: {
    en: 'Are you sure?',
    nb: 'Er du sikker?',
  },
  SendPaymentInformationToInvestorsTooltip: {
    en: 'Here you will find a draft of a notification about payment that can be sent to the selected investors',
    nb: 'Her vil du finne utkast til en melding om innbetaling som kan sendes til de utvalgte investorene',
  },
  NoTasks: {
    en: 'There are no tasks',
    nb: 'Det finnes ingen oppgaver enda',
  },
  NoCompletedTasks: {
    en: 'There are no completed tasks',
    nb: 'Det finnes ingen fullførte oppgaver',
  },
  NoIncompleteTasks: {
    en: 'There are no incomplete tasks',
    nb: 'Det finnes ingen uferdige oppgaver',
  },
  YouHaveNoTasks: {
    en: 'You have no tasks',
    nb: 'Du har ingen oppgaver',
  },
  YouHaveNoIncompleteTasks: {
    en: 'You have no incomplete tasks',
    nb: 'Du har ingen uferdige oppgaver',
  },
  YouHaveNoCompletedTasks: {
    en: 'You have no completed tasks',
    nb: 'Du har ingen fullførte oppgaver',
  },
  YouHaveNoPrivateTasks: {
    en: 'You have no private tasks',
    nb: 'Du har ingen private oppgaver',
  },
  ThereAreNoTasks: {
    en: 'There are no tasks',
    nb: 'Det finnes ingen oppgaver enda',
  },
  PrivateTasks: {
    en: 'Private tasks',
    nb: 'Private oppgaver',
  },
  NoemissionsYet: {
    en: 'There are no ongoing emissions',
    nb: 'Du har ingen pågående kapitalforhøyelser',
  },
  NoInvestorsInvited: {
    en: 'No investors invited',
    nb: 'Ingen investorer er invitert',
  },
  ShareRegisterInfo4: {
    en: 'looks like right now that provide a detailed history of the current membership situation.',
    nb: 'ser ut som akkurat nå som gir en detaljert historikk over den aktuelle medlemssituasjonen.',
  },
  NewContract: {
    en: 'New contract',
    nb: 'Ny kontrakt',
  },
  ContractOwner: {
    en: 'Contract owner',
    nb: 'Kontraktsinnehaver',
  },
  ImportContracts: {
    en: 'Import contracts',
    nb: 'Importer kontrakter',
  },
  ExportContracts: {
    en: 'Export contracts',
    nb: 'Eksporter kontrakter',
  },
  andContractInformation: {
    en: 'and contract information',
    nb: 'og kontraktsinformasjon',
  },
  OrgbrainUses: {
    en: 'Orgbrain uses',
    nb: 'Orgbrain bruker',
  },
  ContractTitle: {
    en: 'Contract title',
    nb: 'Avtaletittel',
  },
  NameOfShareclass: {
    en: 'Name of share class',
    nb: 'Navn på aksjeklasse',
  },
  ImportContractTitleText: {
    en: 'Is the title of the contract, and is used to identify the contract. This is a required field and is used to identify exisitng contracts in the registry.',
    nb: 'Er tittelen på kontrakten, og brukes til å identifisere kontrakten. Dette er et obligatorisk felt og brukes til å identifisere eksisterende kontrakter i registeret.',
  },
  contractedAmount: {
    en: 'Contracted amount',
    nb: 'Kontraktbeløp',
  },
  ImportContractedAmountText: {
    en: 'Is the contracted amount of the contract, and is used as the amount field in contracts. This is a required field and is used to sett the amount in a contract.',
    nb: 'Er kontraktsbeløpet i kontrakten, og brukes som beløpsfelt i kontrakter. Dette er et obligatorisk felt og brukes til å sette beløpet i en kontrakt.',
  },
  contractType: {
    en: 'Contract type',
    nb: 'Kontraktstype',
  },
  ImportContractTypeText: {
    en: 'Is the type of the contract, and is used to identify the contract type. This is an required field and is used to identify exisitng contracts in the registry and sett the contract type.',
    nb: 'Er kontraktstypen, og brukes til å identifisere kontraktstypen. Dette er et obligatorisk felt og brukes til å identifisere eksisterende kontrakter i registeret og sette kontraktstypen.',
  },
  contractStatus: {
    en: 'Contract status',
    nb: 'Kontrakt status',
  },
  ImportContractStatusText: {
    en: 'Is the status of the contract, and is used to identify the contract status. This is an optional field and is used to sett contract status.',
    nb: 'Er kontrakten status, og brukes til å identifisere kontrakten status. Dette er et valgfritt felt og brukes til å sette kontrakten status.',
  },
  contractExpiryDate: {
    en: 'Contract expiry date',
    nb: 'Kontrakt utløpsdato',
  },
  ImportContractExpiryDateText: {
    en: 'Is the expiry date of the contract, and is used to identify the contract expiry date. This is an optional field and is used to sett contract expiry date.',
    nb: 'Er kontraktens utløpsdato, og brukes til å identifisere kontrakten utløpsdato. Dette er et valgfritt felt og brukes til å sette kontrakten utløpsdato.',
  },
  strikePrice: {
    en: 'Strike price',
    nb: 'Innløsningskurs',
  },
  ImportStrikePriceText: {
    en: 'Is the strike price of the contract, and is used to identify the contract strike price. This is a required field and is used to sett contract strike price.',
    nb: 'Er innløsningskursen på kontrakten, og brukes til å identifisere kontrakten innløsningskurs. Dette er et obligatorisk felt og brukes til å sette kontrakten innløsningskurs.',
  },
  totalLoan: {
    en: 'Total loan',
    nb: 'Lånebeløp',
  },
  ImportTotalLoanText: {
    en: 'Is the total loan of the contract, and is used to identify the contract total loan in the loan contract type. This is a optional field and is used in loans to sett contract total loan amount.',
    nb: 'Er lånebeløpet på kontrakten, og brukes til å identifisere kontrakten lånebeløp i lånekontraktstypen. Dette er et valgfritt felt og brukes i lån til å sette kontrakten lånebeløp.',
  },
  effectiveInterestRate: {
    en: 'Effective interest rate',
    nb: 'Effektiv lånerente',
  },
  ImportEffectiveInterestRateText: {
    en: 'Is the effective interest rate of the contract, and is used to identify the contract effective interest rate in the loan contract type. This is a optional field and is only used in loans to sett contract interest rate.',
    nb: 'Er den effektive lånerenten på kontrakten, og brukes til å identifisere kontrakten effektive lånerente i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten effektive lånerente.',
  },
  convertibleShareOfLoan: {
    en: 'Convertible share of loan',
    nb: 'Konverteringsbeløp',
  },
  ImportConvertibleShareOfLoanText: {
    en: 'Is the convertible share of loan of the contract, and is used to identify the contract convertible share of loan in the loan contract type. This is a optional field and is only used in loans to sett contract convertible share of loan.',
    nb: 'Er konverteringsbeløpet på kontrakten, og brukes til å identifisere kontrakten konverteringsbeløp i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten konverteringsbeløp.',
  },
  contractTerms: {
    en: 'Contract terms',
    nb: 'Betingelser',
  },
  ImportContractTermsText: {
    en: 'Is the contract terms of the contract, and is used to identify the contract terms in the loan contract type. This is a optional field and is only used in loans to sett contract terms.',
    nb: 'Er betingelsene på kontrakten, og brukes til å identifisere kontrakten betingelser i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten betingelser.',
  },
  contractPaymentStructure: {
    en: 'Contract payment structure',
    nb: 'Lånets betalingsstruktur',
  },
  ImportContractPaymentStructureText: {
    en: 'Is the contract payment structure of the contract, and is used to identify the contract payment structure in the loan contract type. This is a optional field and is only used in loans to sett contract payment structure.',
    nb: 'Er betalingsstrukturen på kontrakten, og brukes til å identifisere kontrakten betalingsstruktur i lånekontraktstypen. Dette er et valgfritt felt og brukes kun i lån til å sette kontrakten betalingsstruktur.',
  },
  InTheImport: {
    en: 'in the import',
    nb: 'i importen',
  },
  Strikeprice: {
    en: 'Strike price',
    nb: 'Innløsningskurs',
  },
  ContractInvestor: {
    en: 'Investor',
    nb: 'Investor',
  },
  NoShareClasses: {
    en: 'No share classes',
    nb: 'Ingen aksjeklasser',
  },
  CreateShareClassToSolvetheproblem: {
    en: 'You need to create a shareclass or import to use this functionality',
    nb: 'Du må opprette en aksjeklasse eller importere for å bruke denne funksjonaliteten',
  },
  CreateShareclass: {
    en: 'Create share class',
    nb: 'Opprett aksjeklasse',
  },
  Action: {
    en: 'Action',
    nb: 'Handling',
  },
  SendInvitation: {
    en: 'Send invitation',
    nb: 'Send invitasjon',
  },
  SendInvitationEmissionHelpText: {
    en: 'Invite the investor to sign an order form where they can fill out the investment amount.',
    nb: 'Inviter investoren til å signere en tegningsblankett hvor de kan fylle ut investeringsbeløpet.',
  },
  PrefillShares: {
    en: 'Prefill shares',
    nb: 'Forhåndsutfyll aksjer',
  },
  PrefillSharesHelpText: {
    en: 'Register the investor as ordering a specific investment amount (useful if a paper order form has been received)',
    nb: 'Registrer investoren som bestiller et spesifikt investeringsbeløp (nyttig hvis en papirordre er mottatt)',
  },
  AddInvestorHelpText: {
    en: 'Here you can add, prefill shares, or invite a single investor manually. If the investor does not exist in the system from before, you can create a new investor by clicking "New investor".',
    nb: 'Her kan du legge til, fylle ut aksjer for, eller invitere en enkelt investor manuelt. Eksisterer ikke investoren i systemet fra før, kan du lage en ny investor ved å trykke på "Ny investor".',
  },
  Invitations: {
    en: 'Invitations',
    nb: 'Invitasjoner',
  },
  Add: {
    en: 'Add',
    nb: 'Legg til',
  },
  Modified: {
    en: 'Modified',
    nb: 'Endret',
  },
  NewAuthorization: {
    en: 'New authorization',
    nb: 'Ny fullmakt',
  },
  AuthorizationsModuleInfo: {
    en: 'Here the board can register authorizations that the general meeting has given the board. Via the "New authorization" button, you can either add authorizations manually or import all authorizations that are registered in the Brønnøysund Register Center.',
    nb: 'Her kan styret registrere fullmakter som generalforsamlingen har gitt styret. Via knappen "Ny fullmakt" kan du enten legge til fullmakter manuelt eller importere alle fullmakter som er registrert i Brønnøysundregistrene.',
  },
  Summary: {
    en: 'Summary',
    nb: 'Oversikt',
  },
  OrderedInRelationToMinimum: {
    en: 'Ordered in relation to the minimum requirement of',
    nb: 'Bestilt i forhold til minstekravet på',
  },
  shares: {
    en: 'shares',
    nb: 'aksjer',
  },
  ContractOwnerAlt: {
    en: 'Owner',
    nb: 'Innehaver',
  },
  Active: {
    en: 'Active',
    nb: 'Aktive',
  },
  AuthorizationImportError: {
    en: 'Error while importing authorizations',
    nb: 'Feil ved import av fullmakter',
  },
  AuthorizationImportSuccess: {
    en: 'Authorizations imported',
    nb: 'Fullmakter importert',
  },
  DeleteAuthorization: {
    en: 'Delete authorization',
    nb: 'Slett fullmakt',
  },
  DeleteAuthorizationPrompt: {
    en: 'Are you sure you want to delete this authorization?',
    nb: 'Er du sikker på at du vil slette denne fullmakten?',
  },
  InvestorExistsErrorEmission: {
    en: 'This investor already exists, you might want to uncheck new investor and search for them instead',
    nb: 'Denne investoren finnes allerede, du vil kanskje fjerne haken for ny investor og heller søke etter dem',
  },
  SimilarNameEmissionWarning: {
    en: 'There is an investor with a similar name in the registry. If this is the investor you want to add, uncheck new investor and search for this person',
    nb: 'Det finnes en investor med lignende navn i registeret. Hvis dette er investoren du vil legge til fjern hukingen fra ny investor og søk etter denne personen',
  },
  All: {
    en: 'All',
    nb: 'Alle',
  },
  Liquidation: {
    en: 'Liquidation',
    nb: 'Likvidasjon',
  },

  Equal_partial_liquidation: {
    en: 'Equal partial liquidation',
    nb: 'Likvidasjon lik fordeling',
  },
  Unequal_partial_liquidation: {
    en: 'Unequal partial liquidation',
    nb: 'Likvidasjon ulik fordeling',
  },
  Taxable_merger: {
    en: 'Taxable merger',
    nb: 'Skattepliktig fusjon',
  },
  Taxable_demerger: {
    en: 'Taxable demerger',
    nb: 'Skattepliktig fisjon',
  },
  Cancellation_of_treasury_shares_to_cover_losses_and_allocations_to_funds: {
    en: 'Cancellation of treasury shares to cover losses and allocations to funds',
    nb: 'Sletting av egne aksjer for å dekke tap og overføringer til fond',
  },
  Taxable_redemption_in_connection_with_TaxFree_merger_Or_demerger: {
    en: 'Taxable redemption in connection with TaxFree merger Or demerger',
    nb: 'Skattepliktig innløsning i forbindelse med skattefri fusjon eller fisjon',
  },
  Relocation_of_company_to_and_from_Norway: {
    en: 'Relocation of company to and from Norway',
    nb: 'Flytting av selskap til og fra Norge',
  },
  OnlyVisibleToBoard: {
    en: 'To board only',
    nb: 'Til styret',
  },
  YourContract: {
    en: 'Your contract',
    nb: 'Din kontrakt',
  },
  Entity: {
    en: 'Entity',
    nb: 'Entitet',
  },
  Maturity: {
    en: 'Maturity',
    nb: 'Forfall',
  },
  Status: {
    en: 'Status',
    nb: 'Status',
  },
  Strike: {
    en: 'Strike',
    nb: 'Strike',
  },
  LastSharePrice: {
    en: 'Last share price',
    nb: 'Siste aksjekurs',
  },
  EstimatedValueOfPosition: {
    en: 'Estimated value of position',
    nb: 'Estimert verdi på posisjon',
  },
  YourPosition: {
    en: 'Your position',
    nb: 'Din posisjon',
  },
  StrikeRelativeToSharePrice: {
    en: 'Strike relative to share price',
    nb: 'Strike i forhold til aksjekurs',
  },
  NumberOfOwners: {
    en: 'Number of owners',
    nb: 'Antall eiere',
  },
  TotalNumberOfShares: {
    en: 'Total number of shares',
    nb: 'Totalt antall aksjer',
  },
  EstimatedValueOfCompany: {
    en: 'Estimated value of company',
    nb: 'Estimert verdi på selskap',
  },
  Company: {
    en: 'Company',
    nb: 'Selskap',
  },
  YouHaveNoPositions: {
    en: 'You have no positions',
    nb: 'Du har ingen posisjoner',
  },
  YouHaveNoContracts: {
    en: 'You have no contracts',
    nb: 'Du har ingen avtaler',
  },
  Percentage: {
    en: 'Percentage',
    nb: 'Prosent',
  },
  OrderForms: {
    en: 'Order forms',
    nb: 'Bestillingsblanketter',
  },
  InvestorPortfolioInfo: {
    en: 'Here you can view your investments, financial contracts and order forms for ongoing capital raises.',
    nb: 'Her kan du se dine investeringer, finansielle kontrakter og bestillingsblanketter for pågående kapitalforhøyelser.',
  },
  Organization: {
    en: 'Organization',
    nb: 'Organisasjon',
  },
  //BOARDMATCH
  CouldNotGenerateCompanyvalue: {
    en: 'Could not generate company value',
    nb: 'Kunne ikke generere selskapsverdi',
  },
  Error: {
    en: 'Error',
    nb: 'Feil',
  },
  Boardmatch: {
    en: 'Boardmatch',
    nb: 'Styrematch',
  },
  AboutBoardmatch: {
    en: 'About',
    nb: 'Om Styrematch',
  },
  RedirectHeader: {
    en: 'Redirect',
    nb: 'Omdirigering',
  },
  RedirectMessage: {
    en: 'Are you sure you want to redirect to the website below:',
    nb: 'Er du sikker på at du vil omdirigeres til nettstedet nedenfor:',
  },
  MenuCandidate: {
    en: 'Candidate',
    nb: 'Deg som kandidat',
  },
  MenuOrg: {
    en: 'Organization',
    nb: 'Din organisasjon',
  },
  City: {
    en: 'City',
    nb: 'Poststed',
  },
  CandidateProfileInfoBox: {
    en: 'CandidateProfileInfoBox',
    nb: 'Her legger du inn opplysninger om din personlige profil. Det er opp til deg hvor mye informasjon du ønsker å inkludere, men vi anbefaler at du legger inn så mye som mulig for å gjøre det enklere for løsningen å finne en passende match. Ved å merke av for "Offentlig profil" vil informasjonen i seksjonen "Basisinformasjon" være synlig når noen ser på din profil.',
  },
  '3.StartDialogue': {
    en: '3. Start dialogue',
    nb: '3. Innlede dialog',
  },
  StartDialogueDesc: {
    en: 'Both the organization and the candidate kan initiate contact and further dialogue.',
    nb: 'Både virksomheten og kandidaten kan initiere kontakt for videre dialog.',
  },
  RegisterAsCandidate: {
    en: 'Register your organization',
    nb: 'Register deg som kandidat',
  },
  RegisterOrganization: {
    en: 'Register Organization',
    nb: 'Registrer organisasjon',
  },
  AddCandidateProfile: {
    en: 'Add Candidate Profile',
    nb: 'Legg til kandidatprofil',
  },
  NoCandidateProfile: {
    en: 'No Candidate Profile',
    nb: 'Ingen kandidatprofil',
  },
  Profile: {
    en: 'Profile',
    nb: 'Profil',
  },
  Public: {
    en: 'Published',
    nb: 'Publisert',
  },
  NotPublished: {
    en: '(Not published)',
    nb: '(Ikke publisert)',
  },
  Anonymous: {
    en: 'Anonymous',
    nb: 'Anonym',
  },
  AnonymousProfile: {
    en: 'Anonymous Profile',
    nb: 'Anonym profil',
  },
  OrganizationName: {
    en: 'Organization Name',
    nb: 'Organisasjonsnavn',
  },
  Name: {
    en: 'Name',
    nb: 'Navn',
  },
  DeleteProfile: {
    en: 'Delete profile',
    nb: 'Slett profil',
  },
  Edit: {
    en: 'Edit',
    nb: 'Rediger',
  },
  AboutMe: {
    en: 'About me',
    nb: 'Om meg',
  },
  BasicInfo: {
    en: 'Basic info',
    nb: 'Basisinformasjon',
  },
  YearFounded: {
    en: 'Year founded',
    nb: 'År stiftet',
  },
  Birthdate: {
    en: 'Birthdate',
    nb: 'Fødselsdato',
  },
  Age: {
    en: 'Age',
    nb: 'Alder',
  },
  Residence: {
    en: 'Residence',
    nb: 'Bosted',
  },
  Candidates: {
    en: 'Candidates',
    nb: 'Kandidater',
  },
  Nationality: {
    en: 'Nationality',
    nb: 'Nasjonalitet',
  },
  NewInquiry: {
    en: 'New Inquiry',
    nb: 'Ny henvendelse',
  },
  NoInquiries: {
    en: 'No inquiries',
    nb: 'Ingen henvendelser',
  },
  AS: {
    en: 'Joint-Stock Company',
    nb: 'Aksjeselskap',
  },
  ENK: {
    en: 'Sole Proprietorship',
    nb: 'Enkeltpersonforetak',
  },
  ANS: {
    en: 'General Partnership',
    nb: 'Ansvarlig selskap',
  },
  DA: {
    en: 'Partnership with Shared Liability',
    nb: 'Delt ansvar selskap',
  },
  NUF: {
    en: 'Norwegian Branch of Foreign Company',
    nb: 'Norsk avdeling av utenlandsk foretak',
  },
  SA: {
    en: 'Cooperative',
    nb: 'Samvirkeforetak',
  },
  IKS: {
    en: 'Intermunicipal Company',
    nb: 'Interkommunalt selskap',
  },
  KS: {
    en: 'Limited Partnership',
    nb: 'Kommandittselskap',
  },
  BA: {
    en: 'Housing Cooperative',
    nb: 'Borettslag',
  },
  SP: {
    en: 'Limited Liability Company',
    nb: 'Selskap med begrenset ansvar',
  },
  STI: {
    en: 'Foundation',
    nb: 'Stiftelse',
  },
  FLI: {
    en: 'Association/Organization',
    nb: 'Forening/lag/innretning',
  },
  VOF: {
    en: 'Charitable or Nonprofit Organization',
    nb: 'Veldedig eller allmennyttig foretak',
  },
  GFS: {
    en: 'Mutual Insurance Company',
    nb: 'Gjensidig forsikringsselskap',
  },
  HF: {
    en: 'Health Enterprise',
    nb: 'Helseforetak',
  },
  SF: {
    en: 'State Enterprise',
    nb: 'Statsforetak',
  },
  KF: {
    en: 'Municipal Enterprise',
    nb: 'Kommunalt foretak',
  },
  FKF: {
    en: 'County Municipal Enterprise',
    nb: 'Fylkeskommunalt foretak',
  },
  Message: {
    en: 'Message',
    nb: 'Melding',
  },
  WantedRole: {
    en: 'Wanted Role',
    nb: 'Ønsket type verv',
  },
  WantedRoleQ: {
    en: 'What type of position do you seek?',
    nb: 'Hvilken rolle søker dere?',
  },
  WhichWantedRole: {
    en: 'What type of position do you want?',
    nb: 'Hvilken type verv ønsker du deg?',
  },
  NetworkWhichWantedRole: {
    en: 'What type of position would be relevant for the candidate?',
    nb: 'Hvilken type verv kan være relevant for kandidaten?',
  },
  WantedGender: {
    en: 'Wanted gender',
    nb: 'Ønsket kjønn',
  },
  WantedAge: {
    en: 'Wanted Age',
    nb: 'Ønsket aldersintervall',
  },
  WantedEducation: {
    en: 'Wanted education',
    nb: 'Ønsket utdanning',
  },
  PersonalProfile: {
    en: 'My page',
    nb: 'Min side',
  },
  Role: {
    en: 'Role',
    nb: 'Rolle',
  },
  'role.key': {
    en: 'Role Key',
    nb: 'Rolle Nøkkel',
  },
  Business: {
    en: 'Business',
    nb: 'Bransje',
  },
  WorkLanguage: {
    en: 'Work language',
    nb: 'Arbeidsspråk',
  },
  'profile.location?': {
    en: 'Profile Location?',
    nb: 'Profilsted?',
  },
  ShowInterest: {
    en: 'Show interest',
    nb: 'Meld interesse',
  },
  ShowInterestDescCandidate: {
    en: 'The candidate will be notified that you would like to make contact.',
    nb: 'Kandidaten vil få beskjed om at dere er interessert i å ta kontakt.',
  },
  ShowInterestDescOrg: {
    en: 'The organization will be notified that you would like to make contact.',
    nb: 'Organisasjonen vil få beskjed om at du er interessert i å ta kontakt.',
  },
  ShowProfile: {
    en: 'Show Profile',
    nb: 'Vis profil',
  },
  Inquirys: {
    en: 'Inquiries',
    nb: 'Henvendelser',
  },
  Network: {
    en: 'Network',
    nb: 'Nettverk',
  },
  AddNewCandidate: {
    en: 'Add Candidate',
    nb: 'Legg til kandidat',
  },
  AddNewCandidateManual: {
    en: 'Add manually',
    nb: 'Legg til manuelt',
  },
  NoCandidatesInNetwork: {
    en: 'No candidates have been added to your network',
    nb: 'Ingen kandidater er lagt til i ditt nettverk',
  },
  AddToCurrentProfile: {
    en: 'Add to current advertisement',
    nb: 'Legg til i valgt annonse',
  },
  FirstName: {
    en: 'First name',
    nb: 'Fornavn',
  },
  LastName: {
    en: 'Last name',
    nb: 'Etternavn',
  },
  ContactInfo: {
    en: 'Contact info',
    nb: 'Kontaktinfo',
  },
  Email: {
    en: 'E-mail',
    nb: 'E-post',
  },
  PhoneNumber: {
    en: 'Phone number',
    nb: 'Telefonnummer',
  },
  InquiryDate: {
    en: 'Inquiry Date',
    nb: 'Forespørselsdato',
  },
  Contacted: {
    en: 'Contacted',
    nb: 'Kontaktet',
  },
  LastChanged: {
    en: 'Last changed',
    nb: 'Sist endret',
  },
  CreateNewProfile: {
    en: 'Register profile',
    nb: 'Registrer profil',
  },
  NoProfile: {
    en: 'No profiles have been created.',
    nb: 'Dere har ikke ennå opprettet en profil.',
  },
  PublicProfile: {
    en: 'Public profile',
    nb: 'Publisert profil',
  },
  PublicAdvert: {
    en: 'Public advertisement',
    nb: 'Publisert annonse',
  },
  'IN DIALOG': {
    en: 'In dialog',
    nb: 'I dialog',
  },
  CONTACTED: {
    en: 'Contacted',
    nb: 'Kontaktet',
  },
  'NOT CONTACTED': {
    en: 'Not contacted',
    nb: 'Ikke kontaktet',
  },
  ERROR: {
    en: 'ERROR',
    nb: 'FEIL',
  },
  Messages: {
    en: 'Messages',
    nb: 'Meldinger',
  },
  AddNewMessage: {
    en: 'Write a message',
    nb: 'Skriv en melding',
  },
  NewMessage: {
    en: 'Write message...',
    nb: 'Skriv melding...',
  },
  EditMessage: {
    en: 'Edit message',
    nb: 'Rediger melding',
  },
  DeleteMessage: {
    en: 'Delete message',
    nb: 'Slett melding',
  },
  EditedMessage: {
    en: '(edited)',
    nb: '(redigert)',
  },
  InquiryNotes: {
    en: 'Notes',
    nb: 'Notater',
  },
  DescLimit: {
    en: '(Max 750 characters)',
    nb: '(Maks 750 tegn)',
  },
  descPlaceholder: {
    en: 'Description...',
    nb: 'Beskrivelse...',
  },
  CandidateProfile: {
    en: 'Candidate Profile',
    nb: 'Kandidatprofil',
  },
  NetworkCandidateProfile: {
    en: 'Candidate Profile (Network)',
    nb: 'Kandidatprofil (Nettverk)',
  },
  CandidateDescription: {
    en: 'Candidate Description',
    nb: 'Kandidatbeskrivelse',
  },
  Gender: {
    en: 'Gender',
    nb: 'Kjønn',
  },
  FEMALE: {
    en: 'Female',
    nb: 'Kvinne',
  },
  MALE: {
    en: 'Male',
    nb: 'Mann',
  },
  ANY: {
    en: 'Any gender',
    nb: 'Ikke spesifisert',
  },
  OTHERGENDER: {
    en: 'Other',
    nb: 'Annet',
  },
  CONVERSATIONAL: {
    en: 'Conversational',
    nb: 'Grunnleggende',
  },
  FLUENT: {
    en: 'Fluent',
    nb: 'Flytende',
  },
  LinkedIn: {
    en: 'LinkedIn',
    nb: 'LinkedIn',
  },
  Language: {
    en: 'Language',
    nb: 'Språk',
  },
  Proficiency: {
    en: 'Proficiency',
    nb: 'Ferdighetsnivå',
  },
  'Add Language': {
    en: 'Add Language',
    nb: 'Legg til språk',
  },
  Education: {
    en: 'Education',
    nb: 'Utdanning',
  },
  EducationTitle: {
    en: 'Education name',
    nb: 'Utdanningsnavn',
  },
  AddEducation: {
    en: 'Add Education',
    nb: 'Legg til utdanning',
  },
  'educ.key': {
    en: 'Education Key',
    nb: 'Utdanningsnøkkel',
  },
  AndOther: {
    en: 'and other(s)',
    nb: 'og andre',
  },
  OrgbrainBoardSchool: {
    en: 'Orgbrain Board School',
    nb: 'Orgbrain Styreskolen',
  },
  NetworkCompletedOrgbrainBoardSchool: {
    en: 'Has the candidate completed Orgbrain Board School?',
    nb: 'Har kandidaten fullført Orgbrain Styreskolen?',
  },
  NetworkCompletedOtherBoardCourses: {
    en: 'Has the candidate completed other board courses?',
    nb: 'Har kandidaten fullført andre styrekurs?',
  },
  CompletedOrgbrainBoardSchool: {
    en: 'Have you completed Orgbrain Board School?',
    nb: 'Har du fullført Orgbrain Styreskolen?',
  },
  CompletedBoardCourses: {
    en: 'Have you completed any board courses?',
    nb: 'Har du fullført styrekurs?',
  },
  CompletedOtherBoardCourses: {
    en: 'Have you completed other board courses?',
    nb: 'Har du fullført andre styrekurs?',
  },
  CourseType: {
    en: 'Course Type',
    nb: 'Kurstype',
  },
  AddCourse: {
    en: 'Add Course',
    nb: 'Legg til kurs',
  },
  NoCourses: {
    en: 'No Courses',
    nb: 'Ingen kurs',
  },
  Traits: {
    en: 'Personal traits',
    nb: 'Personlige egenskaper',
  },
  TraitsSubheader: {
    en: 'Pick those that are most applicable to you.',
    nb: 'Velg de du mener passer best for deg.',
  },
  NetworkTraitsSubheader: {
    en: 'Pick those that are most applicable to the candidate.',
    nb: 'Velg de du mener passer best for kandidaten.',
  },
  'trait.key': {
    en: 'Trait Key',
    nb: 'Egenskapsnøkkel',
  },
  Industry: {
    en: 'Industry',
    nb: 'Bransje',
  },
  IndustryExperience: {
    en: 'Industry Experience',
    nb: 'Bransjeerfaring',
  },
  'experience.key': {
    en: 'Experience Key',
    nb: 'Erfaringsnøkkel',
  },
  Competancy: {
    en: 'Competency',
    nb: 'Kompetanse',
  },
  'skill.key': {
    en: 'Skill Key',
    nb: 'Ferdighetsnøkkel',
  },
  Experience: {
    en: 'Relevant experience',
    nb: 'Relevant erfaring',
  },
  OrgType: {
    en: 'Org. type',
    nb: 'Org.type',
  },
  From: {
    en: 'From year',
    nb: 'Fra år',
  },
  To: {
    en: 'To year',
    nb: 'Til år',
  },
  VOCATIONAL: {
    en: 'Vocational',
    nb: 'Yrkesfaglig',
  },
  BACHLOR: {
    en: 'Bachelor',
    nb: 'Bachelor',
  },
  MASTER: {
    en: 'Master',
    nb: 'Master',
  },
  EXPERIENCE: {
    en: 'Experience (more than 5 years)',
    nb: 'Bransjeerfaring (mer enn 5 år)',
  },
  ALL: {
    en: 'All',
    nb: 'Alle',
  },
  YOUNG: {
    en: 'Under 35 years',
    nb: 'Under 35 år',
  },
  MIDDEL: {
    en: '35 to 60 years',
    nb: '35 til 60 år',
  },
  OLD: {
    en: 'Over 60 years',
    nb: 'Over 60 år',
  },
  Country: {
    en: 'Country',
    nb: 'Land',
  },
  AddExperience: {
    en: 'Add Experience',
    nb: 'Legg til erfaring',
  },
  InternationalExperience: {
    en: 'International experience',
    nb: 'Internasjonal erfaring',
  },
  InternationalExperienceQ: {
    en: 'Required international experience',
    nb: 'krav om internasjonal erfaring',
  },
  InternationalExperienceImportance: {
    en: 'Is international experience important?',
    nb: 'Er det viktig med internasjonal erfaring?',
  },
  InternationalExperienceYears: {
    en: 'International experience years',
    nb: 'Internasjonale erfaringsår',
  },
  Years: {
    en: ' Years',
    nb: ' År',
  },
  OrgProfileInfoBox: {
    en: 'OrgProfileInfoBox',
    nb: 'Her registrerer dere opplysninger om virksomheten og hvilken type kandidat dere søker. Det er opp til virksomheten å velge hvor mye informasjon man legger inn, men vi anbefaler at dere legger inn så mye som mulig for å gjøre det enklere for løsningen å finne en god match. Hvis dere er ute etter kandidater til ulike verv, må det opprettes en ny profil for hvert verv dere søker etter.',
  },

  BoardmatchSubheader: {
    en: 'We find the right candidate for the right board',
    nb: 'Vi matcher den rette kandidaten med det rette styret',
  },
  BoardmatchDescription: {
    en: 'Boardmatch is a function that connects organizations seeking candidates for board positions, committees, advisory boards, and other corporate roles with candidates looking for such positions. By creating a profile in the system, the organization gains access to candidates who have registered and who match with the specified criteria. Similarly, candidates who register will gain access to businesses where their profile matches. Candidates can choose to have either a visible or anonymous profile. Businesses can also use the system to manage their own database of potential candidates.',
    nb: 'Styrematch er en funksjon som kobler virksomheter som er ute etter kandidater til styret, komiteer, advisory board og andre selskapsverv, med kandidater som er ute etter slike verv. Ved å opprette en profil i løsningen, vil virksomheten få tilgang til de kandidater som har registrert seg og som matcher med de kritene som er angitt. På samme måte vil kandidater som registrerer seg få tilgang til de virksomheter deres profil matcher med. Kandidater kan velge å ha enten en synlig eller anonym profil. Virksomheter kan også bruke systemet til å administrere sin egen database med potensielle kandidater.',
  },
  HowItWorks: {
    en: 'How it works',
    nb: 'Slik fungerer det',
  },
  '1.RegisterProfile': {
    en: '1. Register profile',
    nb: '1. Registrer profil',
  },
  RegisterProfileDesc: {
    en: 'Register your profile with only a few clicks. You decide for yourself how much information to give out.',
    nb: 'Registrer din profil med bare noen få klikk. Du bestemmer selv hvor mye informasjon som skal fylles ut.',
  },
  '2.FindMatch': {
    en: '2. Find match',
    nb: '2. Finn match',
  },
  FindMatchDesc: {
    en: 'The system automatically identifies matching profiles.',
    nb: 'Løsningen identifiserer automatisk profiler som matcher.',
  },
  MinusSharesFound: {
    en: 'Investor with negative shares found',
    nb: 'Investor med negative antall funnet',
  },
  MinusSharesInRegisteryDescription: {
    en: 'The number of shares in the registry is negative for one or more of the investors in the registry. This can cause problems if not solved before the import is completed.',
    nb: 'Antall aksjer i registeret er negative for en eller flere av investorene i registeret. Dette kan føre til problemer om ikke løst før importeringen gjennobmføres.',
  },
  ExpectedRemuneration: {
    en: 'Expected Remuneration',
    nb: 'Forventet honorar',
  },
  NOREM: {
    en: 'No Remuneration',
    nb: 'Ingen honorar',
  },
  remuneration: {
    en: 'Remuneration',
    nb: 'Honorar',
  },
  OrgProfile: {
    en: 'Organization Profile',
    nb: 'Organisasjonsprofil',
  },
  OurPage: {
    en: 'Our page',
    nb: 'Vår side',
  },
  CreateNewAd: {
    en: 'Register advertisement',
    nb: 'Registrer annonse',
  },
  DeleteAd: {
    en: 'Delete advertisement',
    nb: 'Slett annonse',
  },
  AllAds: {
    en: 'All advertisements',
    nb: 'Alle annonser',
  },
  Advert: {
    en: 'Job Advertisement',
    nb: 'Stillingsannonse',
  },
  FindCandidate: {
    en: 'Find candidate',
    nb: 'Finn kandidat',
  },
  FindBoard: {
    en: 'Find board',
    nb: 'Finn verv',
  },
  Personal: {
    en: 'Personal',
    nb: 'Personlig',
  },
  OrganizationProfile: {
    en: 'Organization Profile',
    nb: 'Organisasjonsprofil',
  },
  DescHeader: {
    en: 'About us',
    nb: 'Om virksomheten',
  },
  Turnover: {
    en: 'Turnover',
    nb: 'Omsetning',
  },
  NumEmployees: {
    en: 'Number of Employees',
    nb: 'Antall Ansatte',
  },
  OrgNationality: {
    en: 'Organization Nationality',
    nb: 'Organisasjonsnasjonalitet',
  },
  Norwegian: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  Nordic: {
    en: 'Nordic',
    nb: 'Nordisk',
  },
  International: {
    en: 'International',
    nb: 'Internasjonal',
  },
  LocationImportance: {
    en: 'Location Importance',
    nb: 'Stedsbetydning',
  },
  LocationImportanceQ: {
    en: 'Is it important that the candidate lives geographically nearby?',
    nb: 'Er det viktig at kandidaten bor geografisk nærme?',
  },
  WantedTitle: {
    en: 'Wanted work title',
    nb: 'Ønsket yrkestittel',
  },
  WorkTitle: {
    en: 'Work title',
    nb: 'Yrkestittel',
  },
  OrganizationType: {
    en: 'Organization Type',
    nb: 'Organisasjonstype',
  },
  BoardCourse: {
    en: 'Completed Board Course',
    nb: 'Fullført styrekurs',
  },
  WantedMeetings: {
    en: 'Expected amount of meetings in a year',
    nb: 'Forventet antall møter i året',
  },
  PhysicalMeets: {
    en: 'Physical meetings',
    nb: 'Fysiske møter',
  },
  DigitalMeets: {
    en: 'Digital meetings',
    nb: 'Digitale møter',
  },
  Remuneration: {
    en: 'Remuneration',
    nb: 'Honorar',
  },
  'rem.key': {
    en: 'Remuneration Key',
    nb: 'Lønnsnøkkel',
  },
  NoneValidCandidateProfile: {
    en: 'None Valid Candidate Profile',
    nb: 'Ingen gyldig kandidatprofil',
  },
  AddToContactList: {
    en: 'Add to Contact List',
    nb: 'Legg til i kontaktlisten',
  },
  AlreadySentInquiry: {
    en: 'Already Sent Inquiry',
    nb: 'Allerede sendt forespørsel',
  },
  Duration: {
    en: 'Duration',
    nb: 'Varighet',
  },
  SendInquiry: {
    en: 'Send inquiry',
    nb: 'Send forespørsel',
  },
  ANALYTICAL: {
    en: 'Analytical',
    nb: 'Analytisk',
  },
  STRATEGIC: {
    en: 'Strategic',
    nb: 'Strategisk',
  },
  TEAM_PLAYER: {
    en: 'Team Player',
    nb: 'Lagspiller',
  },
  RELATIONSHIP_BUILDER: {
    en: 'Relationship Builder',
    nb: 'Relasjonsbygger',
  },
  SOLUTION_ORIENTED: {
    en: 'Solution Oriented',
    nb: 'Løsningsorientert',
  },
  CURIOUS: {
    en: 'Curious',
    nb: 'Nysgjerrig',
  },
  PROACTIVE: {
    en: 'Proactive',
    nb: 'Initiativrik',
  },
  INFLUENCER: {
    en: 'Influencer',
    nb: 'Påvirker',
  },
  RELIABLE: {
    en: 'Reliable',
    nb: 'Pålitelig',
  },
  EXECUTION_POWER: {
    en: 'Execution Power',
    nb: 'Gjennomføringskraft',
  },
  FOCUSED: {
    en: 'Focused',
    nb: 'Fokusert',
  },
  SALES: {
    en: 'Sales',
    nb: 'Salg',
  },
  MARKETING: {
    en: 'Marketing',
    nb: 'Markedsføring',
  },
  STRATEGY: {
    en: 'Strategy',
    nb: 'Strategi',
  },
  ECONOMICS: {
    en: 'Economics',
    nb: 'Økonomi',
  },
  LEADERSHIP: {
    en: 'Leadership',
    nb: 'Ledelse',
  },
  COMMUNICATION: {
    en: 'Communication',
    nb: 'Kommunikasjon',
  },
  CHILDREN_EDUCATION: {
    en: 'Children, Education',
    nb: 'Barn, skole og undervisning',
  },
  AUTOMOTIVE: {
    en: 'Automotive',
    nb: 'Bil, kjøretøy og verksted',
  },
  RETAIL: {
    en: 'Retail',
    nb: 'Butikk og varehandel',
  },
  CONSTRUCTION: {
    en: 'Construction',
    nb: 'Bygg og anlegg',
  },
  OPERATIONS_MAINTENANCE: {
    en: 'Operations and Maintenance',
    nb: 'Drift og vedlikeholdstjenester',
  },
  HEALTH_CARE: {
    en: 'Health Care',
    nb: 'Helse og omsorg',
  },
  INFORMATION_TECHNOLOGY: {
    en: 'Information Technology',
    nb: 'IT',
  },
  INDUSTRY_MANUFACTURING: {
    en: 'Industry and Manufacturing',
    nb: 'Industri og produksjon',
  },
  CONSULTING: {
    en: 'Consulting',
    nb: 'Konsulent og rådgivning',
  },
  POWER_AND_ENERGY: {
    en: 'Power and Energy',
    nb: 'Kraft og energi',
  },
  MARITIME_OFFSHORE: {
    en: 'Maritime and Offshore',
    nb: 'Maritim og offshore',
  },
  PUBLIC_ADMINISTRATION: {
    en: 'Public Administration',
    nb: 'Offentlig administrasjon',
  },
  OIL_AND_GAS: {
    en: 'Oil and Gas',
    nb: 'Olje og gass',
  },
  TRANSPORT_LOGISTICS: {
    en: 'Transport and Logistics',
    nb: 'Transport og logistikk',
  },
  POLITICS: {
    en: 'Politics',
    nb: 'Politikk',
  },
  SALG: {
    en: 'Sales',
    nb: 'Salg',
  },
  ADMINISTRATION: {
    en: 'Administration',
    nb: 'Administrasjon',
  },
  HR: {
    en: 'Human Resources',
    nb: 'HR',
  },
  FOU: {
    en: 'Research and Development',
    nb: 'FOU',
  },
  SUSTAINABILITY_ESG: {
    en: 'Sustainability/ESG',
    nb: 'Bærekraft/ESG',
  },
  INTERNATIONALIZATION: {
    en: 'Internationalization',
    nb: 'Internasjonalisering',
  },
  PROGRAMMING: {
    en: 'Programming',
    nb: 'Programmering',
  },
  AI: {
    en: 'Artificial Intelligence',
    nb: 'Al',
  },
  BLOCKCHAIN: {
    en: 'Blockchain',
    nb: 'Blockchain',
  },
  CHAIRMANOFTHEBOARD: {
    en: 'Chairman of the Board',
    nb: 'Styreleder',
  },
  BOARDMEMBER: {
    en: 'Board Member',
    nb: 'Styremedlem',
  },
  ALTERNATEMEMBER: {
    en: 'Alternate Member',
    nb: 'Varamedlem',
  },
  NOMINATIONCOMMITTEE: {
    en: 'Nomination Committee',
    nb: 'Valgkomite',
  },
  REMUNERATIONCOMMITTEE: {
    en: 'Remuneration Committee',
    nb: 'Godgjørelsesutvalg',
  },
  CONTROLCOMMITTEE: {
    en: 'Control Committee',
    nb: 'Kontrollutvalg',
  },
  AUDITCOMMITTEE: {
    en: 'Audit Committee',
    nb: 'Revisjonsutvalg',
  },
  ADVISORYBOARD: {
    en: 'Advisory Board',
    nb: 'Advisory board',
  },
  MANAGINGDIRECTOR: {
    en: 'Managing Director',
    nb: 'Daglig leder',
  },
  ADVISOR: {
    en: 'Advisor',
    nb: 'Rådgiver',
  },
  MENTOR: {
    en: 'Mentor',
    nb: 'Mentor',
  },
  'COMPUTER SCIENCE': {
    en: 'Computer Science',
    nb: 'Informatikk',
  },
  'BUSINESS ADMINISTRATION': {
    en: 'Business Administration',
    nb: 'Bedriftsadministrasjon',
  },
  NURSING: {
    en: 'Nursing',
    nb: 'Sykepleie',
  },
  PSYCHOLOGY: {
    en: 'Psychology',
    nb: 'Psykologi',
  },
  'INTERNATIONAL RELATIONS': {
    en: 'International Relations',
    nb: 'Internasjonale relasjoner',
  },
  'ELECTRICAL ENGINEERING': {
    en: 'Electrical Engineering',
    nb: 'Elektroingeniør',
  },
  MEDICINE: {
    en: 'Medicine',
    nb: 'Medisin',
  },
  ARCHITECTURE: {
    en: 'Architecture',
    nb: 'Arkitektur',
  },
  'MECHANICAL ENGINEERING': {
    en: 'Mechanical Engineering',
    nb: 'Maskiningeniør',
  },
  'CIVIL ENGINEERING': {
    en: 'Civil Engineering',
    nb: 'Byggteknikk',
  },
  'DATA ANALYTICS': {
    en: 'Data Analytics',
    nb: 'Dataanalyse',
  },
  'ENVIRONMENTAL SCIENCE': {
    en: 'Environmental Science',
    nb: 'Miljøvitenskap',
  },
  FINANCE: {
    en: 'Finance',
    nb: 'Økonomi',
  },
  'ARTIFICIAL INTELLIGENCE': {
    en: 'Artificial Intelligence',
    nb: 'Kunstig intelligens',
  },
  'COMMUNICATION STUDIES': {
    en: 'Communication Studies',
    nb: 'Kommunikasjonsstudier',
  },
  BIOTECHNOLOGY: {
    en: 'Biotechnology',
    nb: 'Bioteknologi',
  },
  ASTRONOMY: {
    en: 'Astronomy',
    nb: 'Astronomi',
  },
  'ROBOTICS ENGINEERING': {
    en: 'Robotics Engineering',
    nb: 'Robotteknikk',
  },
  LINGUISTICS: {
    en: 'Linguistics',
    nb: 'Lingvistikk',
  },
  'CHEMICAL ENGINEERING': {
    en: 'Chemical Engineering',
    nb: 'Kjemiteknikk',
  },
  'INDUSTRIAL DESIGN': {
    en: 'Industrial Design',
    nb: 'Industriell design',
  },
  'PUBLIC HEALTH': {
    en: 'Public Health',
    nb: 'Folkehelse',
  },
  'POLITICAL SCIENCE': {
    en: 'Political Science',
    nb: 'Statsvitenskap',
  },
  SOCIOLOGY: {
    en: 'Sociology',
    nb: 'Sosiologi',
  },
  LITERATURE: {
    en: 'Literature',
    nb: 'Litteratur',
  },
  HISTORY: {
    en: 'History',
    nb: 'Historie',
  },
  PHYSICS: {
    en: 'Physics',
    nb: 'Fysikk',
  },
  PHILOSOPHY: {
    en: 'Philosophy',
    nb: 'Filosofi',
  },
  MATHEMATICS: {
    en: 'Mathematics',
    nb: 'Matematikk',
  },
  GEOLOGY: {
    en: 'Geology',
    nb: 'Geologi',
  },
  CHEMISTRY: {
    en: 'Chemistry',
    nb: 'Kjemi',
  },
  'PHYSICAL EDUCATION': {
    en: 'Physical Education',
    nb: 'Kroppsøving',
  },
  PHOTOGRAPHY: {
    en: 'Photography',
    nb: 'Fotografi',
  },
  DANCE: {
    en: 'Dance',
    nb: 'Dans',
  },
  MUSIC: {
    en: 'Music',
    nb: 'Musikk',
  },
  DRAMA: {
    en: 'Drama',
    nb: 'Drama',
  },
  GEOGRAPHY: {
    en: 'Geography',
    nb: 'Geografi',
  },
  ARCHAEOLOGY: {
    en: 'Archaeology',
    nb: 'Arkeologi',
  },
  FORESTRY: {
    en: 'Forestry',
    nb: 'Skogbruk',
  },
  'SOCIAL WORK': {
    en: 'Social Work',
    nb: 'Sosialt arbeid',
  },
  'HEALTHCARE MANAGEMENT': {
    en: 'Healthcare Management',
    nb: 'Helseledelse',
  },
  CRIMINOLOGY: {
    en: 'Criminology',
    nb: 'Kriminologi',
  },
  'URBAN PLANNING': {
    en: 'Urban Planning',
    nb: 'Byplanlegging',
  },
  AGRICULTURE: {
    en: 'Agriculture',
    nb: 'Jordbruk',
  },
  'FASHION DESIGN': {
    en: 'Fashion Design',
    nb: 'Motedesign',
  },
  'BIOMEDICAL ENGINEERING': {
    en: 'Biomedical Engineering',
    nb: 'Biomedisinsk ingeniørvitenskap',
  },
  'INFORMATION TECHNOLOGY': {
    en: 'Information Technology',
    nb: 'Informasjonsteknologi',
  },
  NEUROSCIENCE: {
    en: 'Neuroscience',
    nb: 'Neurovitenskap',
  },
  'AVIATION MANAGEMENT': {
    en: 'Aviation Management',
    nb: 'Luftfartsledelse',
  },
  'HOSPITALITY MANAGEMENT': {
    en: 'Hospitality Management',
    nb: 'Hospitality-ledelse',
  },
  'COGNITIVE SCIENCE': {
    en: 'Cognitive Science',
    nb: 'Kognitiv vitenskap',
  },
  CYBERSECURITY: {
    en: 'Cybersecurity',
    nb: 'Cybersikkerhet',
  },
  'MARINE BIOLOGY': {
    en: 'Marine Biology',
    nb: 'Marinbiologi',
  },
  'HUMAN RESOURCE MANAGEMENT': {
    en: 'Human Resource Management',
    nb: 'Human Resource Management',
  },
  'AEROSPACE ENGINEERING': {
    en: 'Aerospace Engineering',
    nb: 'Luft- og romfart',
  },
  'GRAPHIC DESIGN': {
    en: 'Graphic Design',
    nb: 'Grafisk design',
  },
  'INTERNATIONAL BUSINESS': {
    en: 'International Business',
    nb: 'Internasjonal business',
  },
  'RENEWABLE ENERGY ENGINEERING': {
    en: 'Renewable Energy Engineering',
    nb: 'Fornybar energi teknikk',
  },
  'SPORTS MANAGEMENT': {
    en: 'Sports Management',
    nb: 'Sportsledelse',
  },
  'URBAN DESIGN': {
    en: 'Urban Design',
    nb: 'Bydesign',
  },
  'INTERIOR DESIGN': {
    en: 'Interior Design',
    nb: 'Interiørdesign',
  },
  'PETROLEUM ENGINEERING': {
    en: 'Petroleum Engineering',
    nb: 'Petroleumsingeniør',
  },
  'PUBLIC RELATIONS': {
    en: 'Public Relations',
    nb: 'Offentlige relasjoner',
  },
  'WEB DEVELOPMENT': {
    en: 'Web Development',
    nb: 'Nettutvikling',
  },
  ENGLISH: {
    en: 'English',
    nb: 'Engelsk',
  },
  SPANISH: {
    en: 'Spanish',
    nb: 'Spansk',
  },
  GERMAN: {
    en: 'German',
    nb: 'Tysk',
  },
  FRENCH: {
    en: 'French',
    nb: 'Fransk',
  },
  SWEDISH: {
    en: 'Swedish',
    nb: 'Svensk',
  },
  CHINESE: {
    en: 'Chinese',
    nb: 'Kinesisk',
  },
  RUSSIAN: {
    en: 'Russian',
    nb: 'Russisk',
  },
  ITALIAN: {
    en: 'Italian',
    nb: 'Italiensk',
  },
  JAPANESE: {
    en: 'Japanese',
    nb: 'Japansk',
  },
  ARABIC: {
    en: 'Arabic',
    nb: 'Arabisk',
  },
  NORTHERNSAMI: {
    en: 'Northern Sami',
    nb: 'Nordsamisk',
  },
  PORTUGUESE: {
    en: 'Portuguese',
    nb: 'Portugisisk',
  },
  DUTCH: {
    en: 'Dutch',
    nb: 'Nederlandsk',
  },
  LATIN: {
    en: 'Latin',
    nb: 'Latin',
  },
  GREEK: {
    en: 'Greek',
    nb: 'Gresk',
  },
  DANISH: {
    en: 'Danish',
    nb: 'Dansk',
  },
  FINNISH: {
    en: 'Finnish',
    nb: 'Finsk',
  },
  POLISH: {
    en: 'Polish',
    nb: 'Polsk',
  },
  TURKISH: {
    en: 'Turkish',
    nb: 'Tyrkisk',
  },
  KOREAN: {
    en: 'Korean',
    nb: 'Koreansk',
  },
  HEBREW: {
    en: 'Hebrew',
    nb: 'Hebraisk',
  },
  PERSIAN: {
    en: 'Persian',
    nb: 'Persisk',
  },
  URDU: {
    en: 'Urdu',
    nb: 'Urdu',
  },
  HINDI: {
    en: 'Hindi',
    nb: 'Hindi',
  },
  THAI: {
    en: 'Thai',
    nb: 'Thai',
  },
  VIETNAMESE: {
    en: 'Vietnamese',
    nb: 'Vietnamesisk',
  },
  INDONESIAN: {
    en: 'Indonesian',
    nb: 'Indonesisk',
  },
  HUNGARIAN: {
    en: 'Hungarian',
    nb: 'Ungarsk',
  },
  CZECH: {
    en: 'Czech',
    nb: 'Tsjekkisk',
  },
  ROMANIAN: {
    en: 'Romanian',
    nb: 'Rumensk',
  },
  NORWEGIAN: {
    en: 'Norwegian',
    nb: 'Norsk',
  },
  LinkedInTooltip: {
    en: 'Add a link to your linkedIn in this format: https://www.linkedin.com/in/YOUR USER/',
    nb: 'Legg til en link til din LinkedIn profil i dette formatet: https://www.linkedin.com/in/YOUR USER/',
  },
  TitleToolTip: {
    en: 'Enter a title for your ad, for example what you are looking for',
    nb: 'Legg inn en tittel på din annonse, for eksempel hva du søker etter',
  },
  AllProfiles: {
    en: 'All advertisements',
    nb: 'Alle annonser',
  },
  NoSelectedProfile: {
    en: 'No selected advertisement',
    nb: 'Ingen annonse valgt',
  },
  WantToDelete: {
    en: 'Are you sure you want to delete?',
    nb: 'Er du sikker på at du vil slette?',
  },
  AnErrorHaveOcurred: {
    en: 'An error have occured',
    nb: 'Det har oppstått en feil',
  },
  CheckIfYourEventsAreConsistentIfThisErrorPersistsContactSupport: {
    en: 'Check if your events are consistent. If this error persists, contact support.',
    nb: 'Sjekk om hendelser er konsistente. Hvis denne feilen vedvarer, kontakt support.',
  },
  NoSubscriptionsEmission: {
    en: 'You currently have no subscriptions, historic or ongoing...',
    nb: 'Du har for øyeblikket ingen bestillingsblanketter, verken historiske eller pågående...',
  },
  ShareClassOnly: {
    en: 'Share class',
    nb: 'Aksjeklasse',
  },
  CompanyOnly: {
    en: 'Company',
    nb: 'Selskap',
  },
  Ownershare: {
    en: 'Share of ownership',
    nb: 'Eierandel',
  },
  EstimatedValue: {
    en: 'Estimated value',
    nb: 'Estimert verdi',
  },
  Portfolio: {
    en: 'Portfolio',
    nb: 'Portefølje',
  },
};
